 <div class="md-popup">
    <div class="md-popup-header">
        <div class="title">Edit tags</div>
    </div>
    <div class="tagList md-popup-content">
        <mat-form-field class="tags" *ngIf="documentTags">
            <mat-chip-grid #chipList>
            <mat-chip-row class="tag-chip" *ngFor="let tag of documentTags" [matTooltip]="tag.value" [selectable]="false" [removable]="true" (removed)="removeTag(tag)">
                <div class="text">{{tag.value}}</div>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-row>
            <input mat-input placeholder="Edit Tags for this version..."
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    matChipInputAddOnBlur="addOnBlur"
                    [disabled]="disabled"
                    (matChipInputTokenEnd)="addTag($event)">
            </mat-chip-grid>
        </mat-form-field>
        <div class="actions" *ngIf="!disabled">
            <button class="md-button md-button-outstanding" (click)="close()">Close</button>
        </div>
        <div *ngIf="disabled" class="busy">
            <mat-progress-spinner class="spinner" diameter="25" class="spinner" color="accent" mode="indeterminate"></mat-progress-spinner> 
        </div>
    </div>
</div>