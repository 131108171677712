import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { SEMICOLON, ENTER } from '@angular/cdk/keycodes';
import * as _ from 'underscore';

import { MatChipInputEvent } from '@angular/material/chips';
import { Input } from '@angular/core';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { DocumentService, DocumentTag } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';
import { FeedbackService } from 'src/app/services/feedback.service';


@Component({
  selector: 'tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent implements OnDestroy {

  separatorKeysCodes = [ENTER, SEMICOLON];

  formSubscription: Subscription;

  docTagsNames: string[];
  filteredTags: string[];
  filterTagsController: UntypedFormControl = new UntypedFormControl();
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;


  @Input() disabled: boolean;
  @Input() saveChanges: boolean;
  @Input() docVersionId: string;
  _tags: DocumentTag[];
  @Input() set tags(t: DocumentTag[])
  {
    this._tags = t;
    // if (this._tags && this._tags.length > 0)
    // {
    //   this.tagsLoaded = true;
    // }
    // this.filterTags();
  }
  @Output() tagsChange: EventEmitter<DocumentTag[]> = new EventEmitter<DocumentTag[]>();
  tagsLoaded: boolean;
  busy: boolean;


  constructor(
    private docService: DocumentService,
    private feedbackService: FeedbackService)
  {
    this.formSubscription =
      this.filterTagsController.valueChanges.subscribe(() => this.filterTags());

    this.disabled = this.saveChanges && !this.docVersionId ? true : this.disabled;
  }

  ngOnDestroy(): void
  {
    this.formSubscription.unsubscribe();
  }

  private async getTags()
  {
    if (this.busy || this.tagsLoaded)
    {
      return;
    }
    try
    {
      this.busy = true;
      this.docTagsNames = await this.docService.getExistingDocTagsNames();
      this.filterTags();
      this.busy = false;
    }
    catch (err)
    {
      console.log('Error getting existing document tags');
    }
    finally
    {
      this.tagsLoaded = true;
      this.busy = false;
    }
  }

  filterTags()
  {
    // Filter by text
    if (!this.filterTagsController.value)
    {
      this.filteredTags = this.docTagsNames;
    }
    else
    {
      this.filteredTags = _.filter(this.docTagsNames, t =>
        t.toLowerCase().includes(this.filterTagsController.value.toLowerCase()));
    }

    // Filter by selected tags
    this.filteredTags = _.filter(this.filteredTags, t =>
      !_.any(this._tags, dt => dt.value.toLowerCase() === t.toLowerCase()));
  }

  async addTag(event: MatChipInputEvent)
  {
    if (this.disabled || this.busy)
    {
      return;
    }
    try
    {
      this.busy = true;
      const input = event.input;
      const value = event.value;

      if ((value || '').trim())
      {
        const index = _.findIndex(this._tags, lt => lt.value === value);
        if (index < 0)
        {
          let docTag: DocumentTag = {documentVersionId: this.docVersionId, value: value.trim()};
          if (this.saveChanges)
          {
            docTag = await this.docService.addDocumentTag(docTag);
            this.feedbackService.notifyMessage('Tag successfully added');
          }
          this._tags.push(docTag);
        }
      }
      if (input)
      {
        input.value = '';
      }
      this.filterTags();
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error adding tag', err);
    }
    finally
    {
      this.busy = false;
    }
  }

  tagSelected(event: MatAutocompleteSelectedEvent)
  {
    const value = event.option?.value?.trim();
    if (value)
    {
      if (_.all(this._tags, tag => tag.value.toLowerCase() !== value.toLowerCase()))
      {
        if (this.saveChanges)
        {
          this.addTag({value: value, input: null, chipInput: null});
        }
        else
        {
          this._tags.push(
          {
            value: value
          });
          this.filterTags();
        }
      }
    }
    this.tagInput.nativeElement.value = '';
    this.filterTagsController.setValue(null);
  }

  async removeTag(tag: DocumentTag)
  {
    if (this.disabled || this.busy)
    {
      return;
    }
    try
    {
      this.busy = true;
      const index = this._tags.indexOf(tag);
      if (index >= 0)
      {
        if (this.saveChanges)
        {
          await this.docService.deleteDocumentTag(tag.id);
          this.feedbackService.notifyMessage('Tag successfully removed');
        }
        this._tags.splice(index, 1);
      }
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error removing tag', err);
    }
    finally
    {
      this.busy = false;
    }
  }

  onInputFocus()
  {
    if (!this.tagsLoaded && !this.busy)
    {
      this.getTags();
    }
  }

}
