import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { GuideStep, TranslationService } from '../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
@Injectable
(
    {
    providedIn: 'root'
    }
)

export class GuideService
{
    public sequence: GuideStep [];
    destroyComponentSubscription: Subscription;
    guideJSON: Object;

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
                private appRef: ApplicationRef,
                private injector: Injector,
                private translationService: TranslationService)
    {
        this.translationService.languageChanged.subscribe( () =>
        {
            this.loadTranslationJson();
        });
        this.loadTranslationJson();
    }

    private loadTranslationJson()
    {
        if (this.translationService.currentLanguage === 'en')
        {
            this.guideJSON = require('../../assets/i18n/guide_en.json');
        }
        else
        {
            this.guideJSON = require('../../assets/i18n/guide_es.json');
        }
    }

    private appendComponentToBody( component: any , sequence: GuideStep [])
    {
        const componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);
        // @ts-ignore
        componentRef.instance.sequence = sequence;
        this.appRef.attachView(componentRef.hostView);
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        document.body.prepend(domElem);
        // @ts-ignore
        this.destroyComponentSubscription = componentRef.instance.stopGuide.subscribe( () =>
        {
            this.removeComponentFromBody(componentRef);
        });
    }


    createSequence(component: any , sequence: GuideStep [])
    {
        this.appendComponentToBody(component , sequence);
    }

    removeComponentFromBody(componentRef: ComponentRef < any > )
    {
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
        this.destroyComponentSubscription.unsubscribe();
        this.destroyComponentSubscription = undefined;
    }

    getTranslation(key: string): string
    {
        const keys = key.split('.');
        let lastValue;
        for (const k of keys)
        {
            if (!lastValue)
            {
                lastValue = this.guideJSON[k];
            }
            else
            {
                lastValue = lastValue[k];
            }
        }
        return lastValue;
    }

}