import { Component, OnInit, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SEMICOLON, ENTER } from '@angular/cdk/keycodes';
import * as _ from 'underscore';
import { DocumentTag, DocumentVersion, DocumentService, LoggingService, Severity } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { FeedbackService } from 'src/app/services/feedback.service';


@Component({
  selector: 'app-tag-edit-dialog',
  templateUrl: './tag-edit-dialog.component.html',
  styleUrls: ['./tag-edit-dialog.component.scss']
})
export class TagEditDialogComponent implements OnInit {
  separatorKeysCodes = [ENTER, SEMICOLON];
  disabled: boolean;
  documentTags: DocumentTag [] = [];
  docVersion: DocumentVersion;
  constructor(public dialogRef: MatDialogRef<TagEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private documentService: DocumentService,
              private feedbackService: FeedbackService,
              private logService: LoggingService)
  {
    this.docVersion = data.docVersion;
  }

  ngOnInit()
  {
    this.loadDocumentTags();
  }
  close()
  {
    this.dialogRef.close();
  }

  private loadDocumentTags()
  {
    if (this.docVersion.documentTags)
    {
      this.documentTags = this.docVersion.documentTags;
    }
  }

  async addTag(event: any)
  {
    if (this.disabled)
    {
      return;
    }
    try
    {
      this.disabled = true;
      const input = event.input;
      const value = event.value;
      if ((value || '').trim())
      {
        const tag: DocumentTag = {documentVersionId: this.docVersion.id, value: value.trim()};
        const statusDuplicate = this.checkTagDuplicate(tag);
          if (!statusDuplicate)
          {
            input.value = '';
            try
            {
              const tagResul = await this.documentService.addDocumentTag(tag);
              this.documentTags.push(tagResul);
            }
            catch (err)
            {
              this.feedbackService.notifyError('Error adding tags to the document', err);
            }
          }
      }
      this.disabled = false;
    }
    catch (err)
    {
      this.logService.trackTrace(`tag error: ${err}`, Severity.Error);
    }
  }

  checkTagDuplicate(tagResul: DocumentTag): boolean
  {
    for (const tag of this.documentTags)
    {
      if (tag.value.toLowerCase() === tagResul.value.toLowerCase())
      {
        return true;
      }
    }
    return false;
  }

  removeTag(tag: DocumentTag): void
  {
    if (this.disabled)
    {
      return;
    }
    const index = this.documentTags.indexOf(tag);
    if (index >= 0)
    {
      try
      {
        this.disabled = true;
        this.documentService.deleteDocumentTag(tag.id);
        this.documentTags.splice(index, 1);
      }
      catch (err)
      {
        this.feedbackService.notifyError('Error removing tags of the document' , err);
      }
    }
    this.disabled = false;
  }
}
