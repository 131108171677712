import { Injectable } from '@angular/core';
import {  MatDialog } from '@angular/material/dialog';
import { IllustratorService } from './illustrator.service';
import { DocumentFilter, MoonDeskDocument, EventHubService, DocumentTag, LoggingService } from '../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { BulkImportComponent, SelectedFile } from '../pages/_shared/bulk-import/bulk-import.component';

import * as _ from 'underscore';
import { SaveDocumentService, SaveDocumentStatus, SaveDocumentState, SaveDocumentOptions } from '../pages/work/save-document/save-document.service';
import { ClassSelectorDialogComponent, ClassSelectorDialogResult } from '../pages/class-selector-dialog/class-selector-dialog.component';
import { FilesystemService } from './filesystem.service';
import { FeedbackService } from './feedback.service';

/**
 * Result for importing or saving a new version
 */
export interface SaveResult
{
  /**
   * In case of import, the documentPath can change
   * (if user imports a unsaved document, the service saves it in cache).
   */
  documentPath: string;
  /**
   * -1 in case of error
   * 0 in case of not saved
   * 1 in case of success
   */
  code: number;
  reason?: string; // in case of 0 (not saved)
  errorMessage?: string; // in case of error
  newDocument: MoonDeskDocument;
}

@Injectable({
  providedIn: 'root'
})
export class BulkService
{

  constructor(private saveDocService: SaveDocumentService,
    private illService: IllustratorService,
    private dialog: MatDialog,
    private eventHubService: EventHubService,
    private feedbackService: FeedbackService,
    private fileSystemSevice: FilesystemService,
    private logService: LoggingService)
    {
      this.eventHubService.changeBulkInfos.subscribe((documents: MoonDeskDocument[]) => this.changeBulkInfos(documents));
    }

  public openBulkImport(): Promise<void>
  {
    return new Promise<void>((resolve, reject) => {
      this.dialog.open(BulkImportComponent, {
        width: '90%',
        minWidth: '260px',
        height: 'auto',
        data: {
          //company: company,
          service: this
        },
        disableClose: true
      });
    });
  }

  public async import(filter: DocumentFilter , currentFile: SelectedFile , bulkImportPath: string ,
    progress: (percentage: number, status: string) => void, checkRules: boolean,
     checkPath: boolean, checkName: boolean): Promise<SaveResult>
  {
    return new Promise<SaveResult>(async(res, rej) =>
    {
      const filePath: string = currentFile.path;
      const statusChange = (s: SaveDocumentStatus) =>
      {
        progress(s.overallProgress, s.details);
        if (s.status === SaveDocumentState.error)
        {
          const result: SaveResult =
          {
            code: -1,
            documentPath: s.document.workingCopy,
            newDocument: s.document,
            errorMessage: s.details
          };
          res(result);
        }
        if (s.status === SaveDocumentState.cancelled)
        {
          const result: SaveResult =
          {
            code: 0,
            documentPath: s.document?.workingCopy,
            newDocument: s.document,
            reason: s.details
          };
          res(result);
        }
        if (s.status === SaveDocumentState.done)
        {
          const result: SaveResult =
          {
            code: 1,
            documentPath: filePath,
            newDocument: s.document
          };
          if (s.document)
          {
            result.documentPath = s.document.workingCopy;
          }
          res(result);
        }
      };
      try
      {
        await this.illService.openDocument(filePath);
        const options: SaveDocumentOptions = {
          mode: 'NewDoc',
          suppressDuplicateRule: true,
          suppressRuleConfirmation: !checkRules,
          suppressShareDialog: true,
          suppressVersionCheck: true,
          suppressCompleteClassSelection: true,
          checkChecksum: true,
          exclusiveProgress: statusChange
        };
        const tags: DocumentTag [] = this.getPathTagsFromFile(currentFile , bulkImportPath, checkPath, checkName);
        if (filter.tagChanged)
        {
          tags.push({value: filter.tagChanged});
        }
        await this.saveDocService.saveNewDocument(filter, tags , filePath, options);
        // await this.illService.closeDocument(filePath, false);
      }
      catch (err)
      {
        this.logService.logException(err, null, `Error importing document during bulk import`);
        const result: SaveResult =
        {
          code: -1,
          documentPath: filePath,
          errorMessage: err.message,
          newDocument: undefined
        };
        res(result);
      }
    });
  }


  public getPathTagsFromFile(currentFile: SelectedFile, bulkImportPath: string, checkPath?: boolean, checkName?: boolean ): DocumentTag []
  {
    const tags: DocumentTag [] = [];
    const platform = this.fileSystemSevice.getPlatform();
    const pathWithoutBaseDirectory = currentFile.parsedPath.dir.split(bulkImportPath, 2);
    /* Push file name without extension eg : moondesk.ai =>  name : moondesk */
    if (checkName)
    {
      tags.push({value : currentFile.parsedPath.name});
    }
    if (checkPath)
    {

      let splittedFolders: string [];
      if (platform === 'mac')
      {
        const baseFolder = bulkImportPath.split('/');
        if (baseFolder && baseFolder.length > 1)
        {
          
          tags.push({value : baseFolder[baseFolder.length - 1]});
        }
        splittedFolders = pathWithoutBaseDirectory[1].split('/');
      }
      else
      {
        const baseFolder = bulkImportPath.split(/\\/g);
        if (baseFolder && baseFolder.length > 1)
        {
          tags.push({value : baseFolder[baseFolder.length - 1]});
        }
        splittedFolders = pathWithoutBaseDirectory[1].split(/\\/g);
      }

      if (splittedFolders)
      {
        for ( const folder of splittedFolders)
        {
          if (folder.length > 0)
          {
            tags.push({ value : folder});
          }
        }
      }
    }
    return tags;
  }
  /**
   * Change docInfo (classValues) for all documents
   * Returns a message about what happend
   * Returns error (reject) when something went wrong
   */
  public changeBulkInfos(documents: MoonDeskDocument[]): Promise<string>
  {
    return new Promise<string>((resolve, reject) => {
      if (!documents || documents.length == 0)
      {
        resolve('No documents selected');
        this.feedbackService.notifyMessage('No documents selected');
        return;
      }
      const sameDocTypes = documents.every(d =>  d.documentType.id == documents[0].documentType.id);
      if (!sameDocTypes)
      {
        resolve('Please select only one document type.');
        this.feedbackService.notifyMessage('Please select only one document type.');
        return;
      }

      const docToFilter: MoonDeskDocument =
      {
        company: documents[0].company,
        documentType:  documents[0].documentType,
        classValues: [],
        latestVersion: undefined
      };
      const docIds = _.pluck(documents, 'id');
      const dialogRef = this.dialog.open(ClassSelectorDialogComponent, 
      {
        width: 'auto',
        minWidth: '260px',
        data:
        {
          document: docToFilter,
          showMode: false,
          disableDocType: true,
          docIds: docIds,
          bulkMode: true,
          disableRequiredValues: true,
          title: 'Add document classification'
        }
      });
      dialogRef.afterClosed().subscribe(async (result: ClassSelectorDialogResult) =>
      {
        if (result)
        {
          resolve(`Document infos changed for ${docIds.length} document(s)`);
          this.feedbackService.notifyMessage(`Document infos changed for ${docIds.length} document(s)`);
        }
        else
        {
          resolve('Cancelled by user');
          this.feedbackService.notifyMessage('Cancelled by user');
        }
      });
    });
  }

}
