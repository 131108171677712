import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter,
        OnDestroy, QueryList, ElementRef, ViewChildren, AfterViewInit, ViewChild } from '@angular/core';
import * as _ from 'underscore';
import { Identity, SubTaskMessage, SubTask, DocumentVersion, NotificationHubService,
        TasksService, AuthService,
        PushMessage, TaskHistoryElement } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { FeedbackService } from 'src/app/services/feedback.service';

@Component({
  selector: 'app-task-history',
  templateUrl: './task-history.component.html',
  styleUrls: ['./task-history.component.scss'],
  providers: [DatePipe]
})
export class TaskHistoryComponent implements OnInit, OnDestroy// , AfterViewInit
{
  @ViewChildren('contentElements') contentElements: QueryList<ElementRef>;

  private identity: Identity;
  @Input() currentComment: SubTaskMessage;

  private subs: any[] = [];
  busy: boolean = false;
  // loadingSubTask: boolean = false;
  taskHistory: TaskHistoryElement[];

  private _subTask: SubTask;
  @Input() set subTask(st: SubTask)
  {
    this._subTask = st;
    this.loadSubTaskMessages(1, true);
  }
  @Input() showVersions: boolean;

  comment: string;

  selectedVersionId: string;

  @ViewChild('messages') private chatContainer: ElementRef;

  constructor(
    private notificationService: NotificationHubService,
    private taskService: TasksService,
    private feedbackService: FeedbackService,
    private authService: AuthService,
    private datePipe: DatePipe)
  {
      this.identity = this.authService.getCurrentIdentity();
  }

  async ngOnInit()
  {
    this.subs.push(this.notificationService.taskMessagePushed.subscribe((notif: PushMessage) =>
    {
      const msg = notif.taskNotification.subTaskMessage;
      if (msg && msg.subTaskId && this._subTask && msg.subTaskId === this._subTask.id)
      {
        // to not add duplicates in the chat, better we check...
        if (!_.any(this._subTask.subTaskMessages, stm => stm.id === msg.id))
        {
          this._subTask.subTaskMessages.push(msg);
          this.updateHistory();
        }
      }
    }));
  }

  async ngOnDestroy()
  {
    this.subs.forEach(s => s.unsubscribe());
    // let's see if there are any unread notifications for the messages that we're showing
    const unreadMsgs = this.notificationService.allMessages
          .filter(msg => !msg.taskNotification &&
                        msg.taskNotification.subTaskMessage &&
                        msg.taskNotification.subTaskMessage.subTaskId === this._subTask.id);
    if (unreadMsgs.length > 0)
    {
      await this.notificationService.markAsRead(unreadMsgs);
    }
  }

  async loadSubTaskMessages(page: number, scrollToBottom: boolean)
  {
    if (this._subTask.currentMessagePage !== 0 && this._subTask.currentMessagePage >= this._subTask.totalMessagePages)
    {
      return;
    }
    try
    {
      this.busy = true;
      const response = await this.taskService.getSubTaskMessages({subTaskId: this._subTask.id, page: page});
      if (!this._subTask.subTaskMessages)
      {
        this._subTask.subTaskMessages = [];
      }
      this._subTask.subTaskMessages = _.union(this._subTask.subTaskMessages, response.messages);
      this._subTask.subTaskMessages = _.filter(this._subTask.subTaskMessages, stm => stm.timestampUtc > stm.deletedTimestampUtc);
      this._subTask.currentMessagePage = page;
      this._subTask.totalMessagePages = response.pageCount;
      this.updateHistory();
      if (scrollToBottom)
      {
        await this.delay(200);
        this.scrollToBottom();
      }
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error getting messages', err);
    }
    finally
    {
      this.busy = false;
      this._subTask.subTaskMessagesLoaded = true;
    }
  }

  private delay(ms: number)
  {
    return new Promise( resolve => setTimeout(resolve, ms));
  }

  updateHistory()
  {
    let result: TaskHistoryElement[] = [];
    // get all versions
    if (this._subTask.documentVersions)
    {
      const versionElements: TaskHistoryElement[] = _.map(this._subTask.documentVersions, v =>
      {
        const elem: TaskHistoryElement =
        {
          timestampUtc: v.timestampUtc,
          documentVersion: v
        };
        return elem;
      });
      // @ts-ignore
      result.push(...versionElements);
    }
    // get all subTaskMessages
    if (this._subTask.subTaskMessages)
    {
      const subTaskMsgElements: TaskHistoryElement[] = _.map(this._subTask.subTaskMessages, m =>
        {
        const elem: TaskHistoryElement =
        {
          timestampUtc: m.timestampUtc,
          subTaskMessage: m
        };
        return elem;
      });
      result.push(...subTaskMsgElements);
    }
    // sort by timestamp
    result = _.sortBy(result, e => e.timestampUtc);
    this.taskHistory = result;
  }

  async onScroll(scrollEvent: any)
  {
    const isOnTop = scrollEvent.target.scrollTop < 10;
    const areMorePages = this._subTask.currentMessagePage < this._subTask.totalMessagePages;
    if (!this.busy && isOnTop && areMorePages)
    {
      const oldScrollHeight = this.chatContainer.nativeElement.scrollHeight;

      await this.loadSubTaskMessages(this._subTask.currentMessagePage + 1, false);
      await this.delay(100);

      const scrollHeight = this.chatContainer.nativeElement.scrollHeight - oldScrollHeight;
      // To allow for further scrolling in case of additional pages:
      scrollEvent.target.scrollTop = scrollHeight;
    }
  }

  scrollToBottom(): void
  {
    this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
  }

  filteredHistory(): TaskHistoryElement[]
  {
    if (!this.taskHistory)
    {
      return [];
    }
    return _.filter(this.taskHistory, h => this.showVersions === true || h.documentVersion === undefined);
  }

  isReviewerMode()
  {
    const id = this.authService.getCurrentIdentity();
    return id && id.reviewer;
  }


  selectVersion(version: DocumentVersion)
  {
    if (!this.isReviewerMode())
    {
      this.selectedVersionId = version.id;
    }
  }


  compareDates(element: TaskHistoryElement): boolean
  {
    const previousElementIndex: number = this.taskHistory.indexOf(element) - 1;

    const date1 = new Date(element.timestampUtc).toLocaleDateString();
    const date2 = new Date(this.taskHistory[previousElementIndex].timestampUtc).toLocaleDateString();

    return (date1 === date2);
  }

  getTimestamp(element: TaskHistoryElement): string
  {
    return element.timestampUtc.toLocaleTimeString();
  }

  keydown(event: any)
  {
    if (!event.shiftKey && (event.code === 'Enter' || event.code === 'NumpadEnter'))
    {
      event.preventDefault();
      this.send();
    }
  }

  async send()
  {
    if (this.busy || !this.isValidComment(this.comment)) // this.loading || 
    {
      return;
    }
    try
    {
      this.busy = true;

      const comment = this.comment;
      this.comment = undefined;

      const msg: SubTaskMessage =
      {
        subTaskId: this._subTask.id,
        text: comment,
        subTask: this.subTask,
      };
      const fullMsg = await this.taskService.sendMessage(msg);
      this._subTask.subTaskMessages.push(fullMsg);
      this.updateHistory();
      await this.delay(200);
      this.scrollToBottom();
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error sending message', err);
    }
    this.busy = false;
  }

  private isValidComment(comment: string): boolean
  {
    if (comment && comment.replace(/\s/g, '').length)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  isComment(comment: SubTaskMessage): boolean
  {
    return ( (comment.x > 0 || comment.y > 0) ? true : false );
  }

  setCurrentComment(comment: SubTaskMessage)
  {
    this.feedbackService.notifyMessage('Please , Go to web review to open this comment');
  }

  isDeleteComment(comment: SubTaskMessage): boolean
  {
    // Timestamp default is older than timestamp of comment. If deletedTimestamp is greater than create timestamp , is a delete comment.
    if (this.isComment(comment))
    {
      if ( comment.deletedTimestampUtc && (comment.timestampUtc.getUTCDate() > new Date(comment.deletedTimestampUtc).getUTCDate() ) )
      {
        return false;
      }
        return true;
    }
  }

  isOwnComment(comment: SubTaskMessage): boolean
  {
    let status: boolean = false;
    if (comment)
    {
      if (comment.user && this.identity.user)
      {
        status = comment.user.id === this.identity.user.id;
      }
      else if (comment.reviewer && this.identity.reviewer)
      {
        status = comment.reviewer.id === this.identity.reviewer.id;
      }
    }
    return status;
  }

  async removeComment(comment: SubTaskMessage)
  {
    this.feedbackService.notifyMessage('Please , Go to web review to remove this comment');
    return '';
  }

  isOwnMessage(message: SubTaskMessage)
  {
    const id = this.authService.getCurrentIdentity();
    if (id.user)
    {
      return message.user?.id === id.user.id;
    }
    else if (id.reviewer)
    {
      return message.reviewer.id === id.reviewer.id;
    }
    return false;
  }

  getDateString(stm: SubTaskMessage): string
  {
    const currentDate = new Date();
    const daysDifference = this.getDatesDifferenceDays(currentDate, stm.timestampUtc);
    if (daysDifference === 0)
    {
      return 'Today';
    }
    else if (daysDifference === 1)
    {
      return 'Yesterday';
    }
    else
    {
      return this.datePipe.transform(stm.timestampUtc , 'MMMM d, y');
    }
  }

  private getDatesDifferenceDays(date1: Date, date2: Date): number
  {
    const formattedDate1 = new Date(date1).setHours(0, 0, 0, 0);
    const formattedDate2 = new Date(date2).setHours(0, 0, 0, 0);
    const daysDifference = Math.floor(Math.abs(<any>formattedDate1 - <any>formattedDate2) / (1000 * 60 * 60 * 24));
    return daysDifference;
  }

}
