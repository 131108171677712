import { Injectable, Inject } from '@angular/core';
import * as _ from 'underscore';
import {
    Rule,
    RuleIDs,
    LoggingService,
    DocumentAccessProvider,
    MoonDeskDocument,
    DocumentVersion,
    Severity,
    ContentTextRuleConfiguration,
    TextsFormattingService,
    RuleTextContentResult
} from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { RuleResult } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/_models/rules/RuleResult';
import { IllustratorService } from '../illustrator.service';

export interface EvaluationResult
{
    result: boolean;
    failCause?: string;
    textContentResults?: RuleTextContentResult[];
}

@Injectable({
    providedIn: 'root'
  })
export class TextContentRule implements Rule
{
    id = RuleIDs.TEXT_CONTENT;
    name: string = 'Text Content Check';
    actionName: string = 'Show';

    constructor(private logger: LoggingService,
                private illService: IllustratorService,
                private textsFormattingService: TextsFormattingService)
                {}

    async runRule(document: MoonDeskDocument, docVersion: DocumentVersion, filePath: string, config: any): Promise<RuleResult>
    {
        const textRuleConfig: ContentTextRuleConfiguration = config;

        const result: RuleResult =
        {
            rule: this,
            filePath: filePath,
            applied: false,
            error: true,
            description: config.ruleDescription,
            config: textRuleConfig,
            content: textRuleConfig.checkText
        };

        const classValues = _.map(document.classValues, cv => cv.name ? cv.name.toLowerCase() : '').join(' ');
        const documentType = document.documentType ? document.documentType.name : '';


        const oneLineTextInDoc = this.textsFormattingService.formatString(docVersion.fullText, true, false, false);

        const fullText = oneLineTextInDoc + ' ' + classValues + ' ' + documentType ;

        this.logger.trackTrace('TextContentRule - Last Version:' + JSON.stringify(docVersion.fieldValues), Severity.Information);

        // Check Content Text Rules
        const firstEvalu = textRuleConfig.findText ?
            await this.getEvaluationResult(fullText, textRuleConfig.findText, textRuleConfig.findCondition) :
            {result: true};
        if (firstEvalu.result)
        {
            result.applied = true;
            console.log(`Applying rule ${config.ruleDescription}...`);
            const textSizeControl: 'greaterThan' | 'lessThan' = textRuleConfig.maximumTextSize && textRuleConfig.maximumTextSize !== 0 ?
                'lessThan' :
                'greaterThan';
            const ruleEval: EvaluationResult = await this.getEvaluationResult2(
                oneLineTextInDoc,
                textRuleConfig.checkText,
                textRuleConfig.checkCondition,
                textSizeControl === 'lessThan' ? textRuleConfig.maximumTextSize : textRuleConfig.textSize,
                document.workingCopy,
                textRuleConfig.checkTextCaseSensitive,
                docVersion.fullTextCharacters,
                textRuleConfig.richCheckText,
                textSizeControl,
                textRuleConfig.checkTextBasedOnMaxSize);
            if (ruleEval.result)
            {
                result.error = false;
                result.textContentResults = ruleEval.textContentResults;
                this.logger.trackTrace(
                    `TextContentRule - ### RULE OK (condition ${textRuleConfig.checkCondition}):\n${textRuleConfig.checkText}\nin\n${fullText}`,
                    Severity.Information);
            }
            else
            {
                result.data = ruleEval.failCause;
                result.textContentResults = ruleEval.textContentResults;
                // result.description = 'Fail cause: ' + ruleEval.failCause + ' | ' + result.description;
                this.logger.trackTrace(
                    `TextContentRule - ### RULE ERROR (condition ${textRuleConfig.checkCondition}):\n${textRuleConfig.checkText}\nin\n${fullText}`,
                    Severity.Information);
            }
        }

       return result;
    }

    private async getEvaluationResult2(
        fullText: string,
        searchText: string,
        condition: 'exist' | 'notExist',
        size?: number,
        docPath?: string,
        caseSensitive?: boolean,
        fullTextBoldMarks?: string,
        richSearchText?: string,
        sizeControl?: 'greaterThan' | 'lessThan', checkTextBasedOnMaxSize?: boolean): Promise<EvaluationResult>
    {
        if (condition === 'notExist')
        {
            /**
             * The notExist rules are only for NOR, before 1.31
             * the UI allowed to create AND rules so we fix it here
             */
            searchText = searchText.replace('&&', '||');
        }

        richSearchText ??= searchText;
        const searchPhrases: string[] = richSearchText.split(/\|\||&&/g);
        fullTextBoldMarks ??= fullText;
        const formattedFullText = this.textsFormattingService.formatString(fullTextBoldMarks, true, true, false, true);
        const contentResults: RuleTextContentResult[] = [];

        for (const phrase of searchPhrases)
        {
            const noHtml = this.textsFormattingService.formatHtml(phrase, true, false, false);
            const cleanCondition = this.textsFormattingService.formatString(noHtml, true, false, true);

            let regExpFlags = 'g';
            if (!caseSensitive)
            {
                regExpFlags = regExpFlags + 'i';
            }

            let regExpText = this.textsFormattingService.replaceArabicNumbers(cleanCondition);
            regExpText = this.getWildcardRegex(regExpText);
            const regExp = new RegExp(regExpText, regExpFlags);
            const found = regExp.test(fullText);

            const textWithoutWildCards = this.textsFormattingService.removeMoonWildCards(phrase);
            const boldString = this.textsFormattingService.getMoonBoldString(textWithoutWildCards, true);

            let boldMatch = false;

            let phraseSize = 0;
            if (found)
            {
                const reg = new RegExp(boldString, caseSensitive ? 'g' : 'gi');
                boldMatch = reg.test(formattedFullText);
                phraseSize = await this.getSize(cleanCondition, docPath, sizeControl === 'lessThan' || checkTextBasedOnMaxSize);
            }

            const result: RuleTextContentResult =
            {
                content: cleanCondition,
                found: found,
                boldMatch: boldMatch,
                contentHeightMm: phraseSize,
                sizeMatch: found && (!size || size === 0 || (sizeControl === 'lessThan' ? size > phraseSize : size < phraseSize))
            };

            contentResults.push(result);
        }

        let ruleIsFulfilled = false;
        let failCause: string;
        if (contentResults?.length > 0)
        {
            const existingResults = contentResults.filter(r => r.found);
            const isSemanticValid = this.evaluateRuleExpression(richSearchText, existingResults.map(r => r.content));
            const isBoldValid = existingResults.every(r => r.boldMatch);
            const isSizeValid = existingResults.every(r => r.sizeMatch);

            if (condition === 'exist')
            {
                ruleIsFulfilled = isSemanticValid && isBoldValid && isSizeValid;
                if (!ruleIsFulfilled)
                {
                    if (!isSemanticValid)
                    {
                        failCause = 'Content not exist';
                    }
                    else if (!isBoldValid)
                    {
                        failCause = 'Content is not respecting Bold Styles.';
                    }
                    else if (!isSizeValid)
                    {
                        failCause = 'Content height size is not valid';
                    }
                }
            }
            else
            {
                ruleIsFulfilled = !isSemanticValid;
                if (!ruleIsFulfilled)
                {
                    failCause = 'Content should not exist';
                }
            }
        }
        return {result: ruleIsFulfilled, failCause: failCause, textContentResults: contentResults};
    }

    private evaluateRuleExpression(expression: string, existingPhrases: string[]): boolean
    {
        const noHtml = this.textsFormattingService.formatHtml(expression, true, false, false);
        const sections = noHtml.split(/(\|\||&&)/);

        let result: boolean = true;
        let operator: string = '&&';

        sections.forEach((section) =>
        {
            if (section === '&&' || section === '||')
            {
                operator = section;
            }
            else
            {
                const cleanText = this.textsFormattingService.formatString(section, true, false, true);
                const phraseExists = existingPhrases.includes(cleanText);
                if (operator === '&&')
                {
                    result = result && phraseExists;
                }
                else if (operator === '||')
                {
                    result = result || phraseExists;
                }
            }
        });

        return result;
    }

    async getEvaluationResult(fullText: string, searchText: string, condition: 'exist' | 'notExist',
                              size?: number, docPath?: string, caseSensitive?: boolean, fullTextBoldMarks?: string,
                              richSearchText?: string, sizeControl?: 'greaterThan' | 'lessThan', checkTextBasedOnMaxSize?: boolean): Promise<EvaluationResult>
    {
        console.log(fullTextBoldMarks);
        const evaluationResult: EvaluationResult = {result : undefined};
        let regExpText = '';
        let textSize: number;

        if (condition == "notExist")
        {
            /**
             * The notExist rules are only for NOR, before 1.31
             * the UI allowed to create AND rules so we fix it here
             */
            searchText = searchText.replace('&&', '||');
        }
        const orConditions: string[] = searchText.split(/\|\|/g).filter(Boolean);
        for (let orCondition of orConditions)
        {
            orCondition = orCondition.trim();
            regExpText += regExpText !== '' ? '|' : '';
            const andConditions: string[] = orCondition.split('&&');
            
            const isOnlyOr = andConditions.length === 1;

            for (let phrase of andConditions)
            {
                phrase = phrase.trim();
                const cleanCondition = this.textsFormattingService.formatString(phrase, true, false, true);
                regExpText += this.getWildcardRegex(cleanCondition);

                if (size && size > 0)
                {
                    const phraseSize = await this.getSize(phrase, docPath, sizeControl === 'lessThan' || checkTextBasedOnMaxSize);

                    const isTextSizeZero = !textSize || textSize === 0;
                    const isPhraseSizeNonZero = phraseSize !== 0;
                    const shouldBeGreater = sizeControl === 'greaterThan';
                    const shouldBeLower = sizeControl === 'lessThan';

                    if (isTextSizeZero ||
                        (isPhraseSizeNonZero &&
                            ((!isOnlyOr && ((shouldBeGreater && textSize > phraseSize) || (shouldBeLower && textSize < phraseSize))) ||
                             (isOnlyOr && ((shouldBeGreater && textSize < phraseSize) || (shouldBeLower && textSize > phraseSize))))))
                    {
                        // For rules that exclusively contain OR operators, if at least one condition passes the size check,
                        // the rule should pass as well. In this case:
                        // - If sizeControl equals 'greaterThan', we want to keep the largest text size among those separated by OR operators.
                        // - If sizeControl equals 'lessThan', we want to keep the smallest text size among those separated by OR operators.
                        // For rules that include AND operators, the opposite is desired.
                        textSize = +phraseSize.toFixed(2);
                    }
                }
            }
        }

        let regExpFlags = 'g';
        if (!caseSensitive)
        {
            regExpFlags = regExpFlags + 'i';
        }

        regExpText = this.textsFormattingService.replaceArabicNumbers(regExpText);

        const regExp = new RegExp(regExpText, regExpFlags);
        const testResult = regExp.test(fullText);

        if (condition === 'exist')
        {
            evaluationResult.result = testResult;
            if (!testResult)
            {
                evaluationResult.result = false;
                evaluationResult.failCause = 'Content not exist';
                if (caseSensitive)
                {
                    evaluationResult.failCause = evaluationResult.failCause + ' (case sensitive)';
                }
            }
            else if (textSize === 0)
            {
                // Should not happen
                evaluationResult.result = false;
                evaluationResult.failCause = 'Cannot find the minimum text size';
            }
            else if (size)
            {
                switch (sizeControl)
                {
                    case 'lessThan':
                        if (size < textSize)
                        {
                            evaluationResult.result = false;
                            evaluationResult.failCause =
                                `Content height size (${textSize}mm) is bigger than maximum height size of rule (${size}mm). `;
                        }
                        break;
                    default: // greaterThan
                        if (size > textSize)
                        {
                            evaluationResult.result = false;
                            evaluationResult.failCause =
                                `Content height size (${textSize}mm) is lower than minimun height size of rule (${size}mm). `;
                        }
                        break;
                }

                evaluationResult.failCause = `${evaluationResult.failCause}. ${checkTextBasedOnMaxSize ?
                                                                            'Size is based on maximun uppercase height' :
                                                                            'Size is based on minimum lowercase height'}`;
            }
            else if (size < 0)
            {
                // Should not happen but added as a fallback for bug 6928
                evaluationResult.result = false;
                evaluationResult.failCause = 'Negative minimum size is not allowed';
            }

            /* Logic to check bold styles */
            if (testResult && evaluationResult.result && fullTextBoldMarks && richSearchText)
            {
                const formattedFullText = this.textsFormattingService.formatString(fullTextBoldMarks, true, true, false, true);
                const textWithoutWildCards = this.textsFormattingService.removeMoonWildCards(richSearchText);

                // For now we will only check that the bold text is bold
                // (if all the text is bold and only a part of it should be, the rule will still pass).
                // REGULAR TEXT IS NOT CONTROLLED! In the future this could be based on some configuration or condition.

                let testBold = false;
                for (const andCondition of textWithoutWildCards.split('&&'))
                {
                    // if (onlyRegularCheck) {
                        const formattedSearchText = this.textsFormattingService.getMoonBoldString(andCondition, true);
                    // }
                    // else {
                        // const formattedSearchText = this.textsFormattingService.getMoonBoldString(textWithoutWildCards, false);
                    // }

                    const reg = new RegExp(formattedSearchText, caseSensitive ? 'g' : 'gi' );
                    testBold = reg.test(formattedFullText);
                    if (!testBold)
                    {
                        break;
                    }
                }

                if (!testBold)
                {
                    evaluationResult.result = false;
                    evaluationResult.failCause = `Content is not respecting Bold Styles.`;
                }
            }
        }
        else
        {
            if (testResult)
            {
                evaluationResult.result = false;
                evaluationResult.failCause = 'Content should not exist';
            }
            else
            {
                evaluationResult.result = true;
            }
        }

        return evaluationResult;
    }

    async getSize(text: string, docPath: string, biggestSize: boolean): Promise<number>
    {
        let wholeWord = true;
        const txt = this.textsFormattingService.replaceArabicNumbers(text);
        const textWithoutWildCards = this.textsFormattingService.removeMoonWildCards(txt.trim());
        if (textWithoutWildCards !== txt)
        {
            // The text has wildcards so we look for the text within other sentences
            wholeWord = false;
        }
        const oneLineText = this.textsFormattingService.removeLineBreaks(textWithoutWildCards).trim();
        const size = await this.illService.getTextSize(docPath, oneLineText, wholeWord, biggestSize);
        return size;
    }

    private getWildcardRegex(cleanCondition: string): string
    {
        let result: string = '';
        const regExpPartialWord = /wContains:/g;
        const regExpStartsWithWord = /wStarts:/g;
        const regExpEndsWithWord = /wEnds:/g;
        if (regExpPartialWord.test(cleanCondition))
        {
            cleanCondition = cleanCondition.replace(/wContains:/g, '').trim();
            result = `(?=.*(${cleanCondition}))`;
        }
        else if (regExpStartsWithWord.test(cleanCondition))
        {
            cleanCondition = cleanCondition.replace(/wStarts:/g, '').trim();
            result = `(?=.*(\\W|^)(${cleanCondition}))`;
        }
        else if (regExpEndsWithWord.test(cleanCondition))
        {
            cleanCondition = cleanCondition.replace(/wEnds:/g, '').trim();
            result = `(?=.*(${cleanCondition})(\\W|$))`;
        }
        else
        {
            result = `(?=.*(\\W|^)(${cleanCondition.trim()})(\\W|$))`;
        }

        return result;
    }

    action = (result: RuleResult) =>
    {
        alert(`todo select texts in document ${result.filePath}`);
    }

    parseConfig (config: string): any
    {
        let configuration: ContentTextRuleConfiguration;
        if (config)
        {
            try
            {
                config = config.replace(/(?:\\r|\\n)/g, ' ');
                configuration = JSON.parse(config);
            }
            catch (error)
            {
                throw Error('Bad configuration json string');
            }
            if (configuration.checkCondition !== 'exist' && configuration.checkCondition !== 'notExist')
            {
                throw Error('Bad configuration - The allowed values for checkCondition are exist or notExist');
            }
            if (!configuration.checkText)
            {
                throw Error('Bad configuration - CheckText is required');
            }
            if (!configuration.ruleDescription)
            {
                throw Error('Bad configuration - RuleDescription is required');
            }
            return configuration;
        }
        throw Error('Bad configuration - the configuration can\'t be null');
    }

}
