import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, Inject, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as _ from 'underscore';
import {
  SubTaskMessage,
  SubTask,
  Identity,
  PlatformInfoProvider,
  TasksService,
  ImageComparationService,
  AuthService
} from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { MoonZoomAPI } from '../_shared/moonZoom/moonZoom-api';
import { MoonZoomConfig } from '../_shared/moonZoom/moonZoom-config';
import { MoonZoomModel } from '../_shared/moonZoom/moonZoom-model';
import { FeedbackService } from 'src/app/services/feedback.service';


export class MousePosition
{
  x: number;
  y: number;
}

@Component({
  selector: 'image-zoom',
  templateUrl: './image-zoom.component.html',
  styleUrls: ['./image-zoom.component.scss']
})
export class ImageZoomComponent implements OnInit, OnDestroy
{
  panZoomConfig: MoonZoomConfig = new MoonZoomConfig;
  panZoomAPI: MoonZoomAPI;
  zoomPercentage = new UntypedFormControl();

  showControls: boolean;
  imageLoaded: boolean;
  fullscreen: boolean;
  showComparation: boolean = false;
  comparationResult: string = undefined;
  imageMatchPercentage: number = undefined;
  rotateValue = 0;
  //DEV PURPOSE WITHOUT TYPE , COMMENTS
  @Input() _documentVersion: number;
  @Input() set updateSubTaskMessages ( subTaskMessages: SubTaskMessage [])
  {
    if (subTaskMessages && this.imageLoaded)
    {
      this.filterComments(subTaskMessages);
    }

  }
  @Output() newCommentAdded: EventEmitter <SubTaskMessage> = new EventEmitter<SubTaskMessage> ();
  @Input() set documentVersion ( documentVersion: number)
  {
    if (this.isShowingComment)
    {
       this.closeComment();
    }
    this._documentVersion = documentVersion;
    this.filterComments(this._subTaskMessages);
  }
  @Input() subTask: SubTask;
  imageSrc: string;
  addComment: boolean = false;
  showTemporaryComment: boolean = false;
  private  canvas: HTMLCanvasElement;
  private imageElement: HTMLImageElement;
  private comment: any;
  busy: boolean = false;
  private commentinput: any;
  percentage: number;
  commentform = new UntypedFormControl();
  environment: 'WEB' | 'ILST';
  //todo make this a @Input() named 'enableComments'
  //(eventually we just want to show it during reviews)
  @Input() enableComments: boolean;
  @Input() currentComment: SubTaskMessage;
  @Input() set _currentComment (comment: SubTaskMessage)
  {
    if (comment && this.imageLoaded)
    {
      this.resetView();
      this.hidecomments = false;
      this.currentComment = comment;
    }
  }
  @Output() selectComment: EventEmitter <SubTaskMessage> = new EventEmitter<SubTaskMessage>();
  hidecomments: boolean = false;
  identity: Identity;
  isShowingComment: boolean = false;
  filteredComments: SubTaskMessage [];
  @Input() set src(src: string)
  {
    this.imageSrc = src;
    this.imageLoaded = false;
    this.fullscreen = false;
    this.comparationResult = undefined;
    this.showComparation = false;
    this.imageMatchPercentage = undefined;
  }
  imageToCompareSrc: string;
  @Input() set srcToCompare(srcToCompare: string)
  {
    this.imageToCompareSrc = srcToCompare;
  }
  ownComment: boolean;
  @Input() allowEdit: boolean;
  _subTaskMessages: SubTaskMessage [];
  @Input() set subTaskMessages( stm: SubTaskMessage [])
  {
    this._subTaskMessages = stm;
    this.filterComments(stm);
    this.closeComment();
    console.log('Change');
  }
  @ViewChild('image', { static: true }) image: ElementRef;

  maxZoomLevels = 10;
  constructor(@Inject('PlatformInfoProvider') private platformInfoProvider: PlatformInfoProvider,
              private taskService: TasksService,
              private imageComparationService: ImageComparationService,
              private feedbackService: FeedbackService,
              private authService: AuthService)
  {
    this.panZoomConfig.api.subscribe((api: MoonZoomAPI) => this.panZoomAPI = api);
    this.panZoomConfig.modelChanged.subscribe((model: MoonZoomModel) => this.onModelChanged(model));
    window.onresize = (e) =>
    {
        this.resetView();
    };

    this.panZoomConfig.freeMouseWheel = false;
    this.panZoomConfig.invertMouseWheel = true;
    this.panZoomConfig.zoomLevels = this.maxZoomLevels;

    this.zoomPercentage.setValue('100%');
    this.identity = this.authService.getCurrentIdentity();

  }

  async ngOnInit() {
    this.filterComments(this._subTaskMessages);

    const fi = await this.platformInfoProvider.getFrontendInfo();
    this.environment = fi.frontendName;
    if (fi.nodePlatform === 'darwin')
    {
      this.panZoomConfig.panOnClickDrag = false;
      this.showControls = true;
    }
  }

  ngOnDestroy() {
    this.panZoomConfig.api.unsubscribe();
  }

  private onModelChanged(model: MoonZoomModel)
  {
    const percentage = ((model.zoomLevel + 1) * (100 * this.maxZoomLevels) / this.maxZoomLevels);
    this.zoomPercentage.setValue(Math.round(percentage) + '%');
  }

  delay(ms: number)
  {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  async onLoadImage()
  {
    this.imageLoaded = true;
    this.resetView();
  }

  zoomIn()
  {
    this.panZoomAPI.zoomIn();
  }

  zoomOut()
  {
    this.panZoomAPI.zoomOut();
  }

  panImage(action: 'UP' | 'DOWN' | 'LEFT' | 'RIGHT')
  {
    if (!this.showControls)
    {
      return;
    }
    switch (action)
    {
      case 'UP':
        this.panZoomAPI.panDelta({x: 0, y: -50});
        break;
      case 'DOWN':
        this.panZoomAPI.panDelta({x: 0, y: 50});
        break;
      case 'LEFT':
        this.panZoomAPI.panDelta({x: -50, y: 0});
        break;
      case 'RIGHT':
        this.panZoomAPI.panDelta({x: 50, y: 0});
        break;
    }
  }

  async resetView()
  {
    const image = this.image.nativeElement as HTMLImageElement;
    // Wait for initial panZoom zoomToFit
    await this.delay(100);
    // The initial zoomToFit is for width fit only, so we make another zoomToFit for width - height fit
    this.panZoomAPI.zoomToFit({x: 0, y: 0, width: image.width, height: image.height});
    // Wait for the full image zoomToFit
    await this.delay(300);
    // Assign the zoom level to calculate the percentage
    this.onModelChanged(this.panZoomAPI.model);
  }

  toggleFullScreen()
  {
    this.fullscreen = !this.fullscreen;
    this.resetView();
    this.toggleCanvas();
    this.closeComment(); // Make a reset of comment when toggle full screen.
  }


  //compareData: any;
  async compareImages()
  {
    this.hidecomments = true;
    this.imageLoaded = false;
    if (!this.comparationResult)
    {
      this.comparationResult = await this.imageComparationService.localImageCompare(this.imageSrc, this.imageToCompareSrc);
    }
    this.showComparation = !this.showComparation;
    this.imageLoaded = true;
  }

  rotateImages()
  {
    if (this.rotateValue >= 3)
    {
      this.rotateValue = 0;
    }
    else
    {
      this.rotateValue++;
    }
  }


  prepareToDraw()
  {
    this.addComment = true;
    this.prepareCanvas();
    this.hidecomments = false;
  }

  prepareCanvas()
  {
    this.canvas = <HTMLCanvasElement> document.getElementById('canvas');
    this.imageElement = <HTMLImageElement> document.getElementById('imageSrc');
    this.canvas.width = this.imageElement.offsetWidth;
    this.canvas.height = this.imageElement.offsetHeight;
  }

  draw(event)
  {
    if (this.addComment)
    {
      //this.currentComment = undefined;
      this.commentform.setValue('');
      const mouseposition: MousePosition = this.getMousePos(this.canvas, event);
      this.showTemporaryComment = true;
      this.setCommentIconStyle(mouseposition);
    }
  }

  setCommentIconStyle(mouseposition: MousePosition)
  {
    this.comment = document.getElementById('comment');
    this.comment.style.zIndex = '999';
    this.comment.style.left = (mouseposition.x - 71) + 'px';
    this.comment.style.top = (mouseposition.y - 71) + 'px';
    this.comment.style.visibility = 'visible';
  }

  hideCommentIcon()
  {
    if (this.comment)
    {
      this.comment.style.visibility = 'hidden';
    }
  }

  getMousePos(canvas , evt): MousePosition
  {
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width  / rect.width;
    const scaleY = canvas.height / rect.height;
    return {
      x: (evt.clientX - rect.left) * scaleX,
      y: (evt.clientY - rect.top)  * scaleY
    };
  }

  async saveComment()
  {
    if ( this.isValidComment( this.commentform.value))
    {
      this.busy = true;
      try
      {
         const msg: SubTaskMessage =
          {
            subTaskId: this.subTask.id,
            documentVersion : this._documentVersion,
            text: this.commentform.value,
            subTask: this.subTask,
            x : this.comment.style.left === 0 ?  1 :  parseFloat(this.comment.style.left) ,  // make float ENDPOINT
            y : this.comment.style.top === 0 ? 1 : parseFloat(this.comment.style.top)
          };

        const response: SubTaskMessage = await this.taskService.sendMessage(msg);
        this._subTaskMessages.push(response);
        this.filterComments(this._subTaskMessages);
        this.feedbackService.notifyMessage('Comment was sent successfully');
        this.newCommentAdded.emit(response);
      }
      catch (err)
      {
        console.log(err); // DEV PURPOSE
        this.feedbackService.notifyMessage('Error to send comment');
      }
    }
    this.closeComment();
    this.busy = false;
  }

  closeComment()
  {
    this.addComment = false;
    this.hideCommentIcon();
    this.commentform.setValue('');
    this.showTemporaryComment = false;
  }

  private isValidComment(comment: string): boolean
  {
    if (comment && comment.replace(/\s/g, '').length)
    {
      return true;
    }
    else
    {
      this.commentinput.focus();
      this.feedbackService.notifyMessage('Comment is invalid.');
      return false;
    }
  }

  private toggleCanvas()
  {
    try
    {
      const image = document.getElementById('imageSrc');
      this.canvas.width = parseFloat(image.style.width);
      this.canvas.height = parseFloat(image.style.height);
    }
    catch (err)
    {
      console.log(err);
    }
  }

  hideComments()
  {
    if (this.isShowingComment)
    {
       this.closeComment();
    }
    this.hidecomments = this.hidecomments ? false : true;
  }

  getCoordinates(comment: any  , position: 'TOP' | 'LEFT'): string
  {
    let coordinate =  position === 'TOP' ? comment.y : comment.x;
    coordinate = this.environment === 'ILST' ? coordinate = coordinate + 10 : coordinate;
    return coordinate + 'px';
  }

  getCommentUserName(comment: SubTaskMessage): string
  {
    return comment.user ? comment.user.username : ( comment.reviewer.name ?  comment.reviewer.name : comment.reviewer.email ) ;
  }

  getInfomessage(comment: SubTaskMessage): string
  {
    return  this.getCommentUserName(comment).toUpperCase() + ' made the comment ,  at the date (' + this.getDatestamp(comment)  + ' ' + this.getTimestamp(comment)  + ') Comment : ' + comment.text + ', Under version :  ' + comment.documentVersion;
  }

   getTimestamp(comment: SubTaskMessage): string
  {
    return comment.timestampUtc.toLocaleTimeString();
  }
   getDatestamp(comment: SubTaskMessage): string
  {
    const date = comment.timestampUtc;
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  filterComments(subTaskMessages: SubTaskMessage [])
  {
    this.filteredComments = _.filter( subTaskMessages , stm =>   ((stm.x > 0)  || (stm.y > 0))  &&
                                                                 (stm.documentVersion === this._documentVersion) &&
                                                                 ( (stm.timestampUtc > new Date(stm.deletedTimestampUtc)))
                                    );
  }

  setCurrentComment(comment: SubTaskMessage)
  {
    this.selectComment.emit(comment);
    this.currentComment = comment;
  }


}
