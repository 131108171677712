import { Injectable } from '@angular/core';
import {  MatDialog } from '@angular/material/dialog';
import { TextsFormattingService } from '../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { CurrentDocumentService } from '../pages/work/current-document-manager/current-document.service';
import { PasteContentPopupComponent } from '../pages/_shared/paste-content-popup/paste-content-popup.component';
import { IllustratorFont } from '../_models/smartmapping';
import { IllustratorService } from './illustrator.service';
import { LibraryCacheService } from './library-cache.service';

export interface FontResponse
{
  normalFont: IllustratorFont;
  boldFont: IllustratorFont;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentContentsService {

  constructor(
    private textsFormattingService: TextsFormattingService,
    private currentDocumentService: CurrentDocumentService,
    private libService: LibraryCacheService,
    private illService: IllustratorService,
    private dialog: MatDialog)
  {}

  async addTextContent(textContent: string, minSize: number)
  {
    const fontsSelected: FontResponse = await this.askFontOptions();
    if (!fontsSelected)
    {
      return '';
    }
    const cleanContent = this.textsFormattingService.formatHtml(textContent, true, false, true).trim();
    const div = document.createElement('div');
    div.innerHTML = cleanContent;
    const boldElements = div.querySelectorAll('b,strong');
    const text = div.innerText;
    const boldIterate = Array.prototype.slice.call(boldElements);
    // Remove selected text of current textFrame.
    await this.currentDocumentService.removeSelectedText();
    if (boldIterate && boldIterate.length > 0)
    {
      for (let i = 0; i < boldIterate.length; i++)
      {
        const boldElement = boldIterate[i];
        if (boldElement.innerText.trim().length > 0)
        {
          // ALT + 244   AND ALT + 245
          boldElement.innerText = '¶' + boldElement.innerText + '§';
        }
      }
      const finalText = div.innerText;
      let textToSend = '';
      let boldFlag = false;
      for (let i = 0; i < finalText.length; i++)
      {
        if (finalText[i] !== '¶' && !boldFlag)
        {
          textToSend = textToSend + finalText[i];
        }
        else if (finalText[i] === '¶' && !boldFlag)
        {
          boldFlag = true;
          await this.sendContent(textToSend, minSize, 'NORMAL', fontsSelected.normalFont, fontsSelected.boldFont);
          textToSend = '';
        }
        else if (boldFlag && finalText[i] !== '§')
        {
          textToSend = textToSend + finalText[i];
        }
        else if (boldFlag && finalText[i] === '§')
        {
          boldFlag = false;
          await this.sendContent(textToSend, minSize, 'BOLD', fontsSelected.normalFont, fontsSelected.boldFont);
          textToSend = '';
        }
        if (textToSend && textToSend.length > 0 && !finalText[i + 1])
        {
          await this.sendContent(textToSend, minSize, 'NORMAL', fontsSelected.normalFont, fontsSelected.boldFont);
        }
      }
    }
    else
    {
      await this.sendContent(text, minSize, 'NORMAL', fontsSelected.normalFont, fontsSelected.boldFont);
    }

    // await this.illService.deselectAll();
  }

  async addLibContent(docVersionId: string)
  {
    const libraryElements = await this.libService.getLibraryElementsByIds([docVersionId]);
    if (!libraryElements || !libraryElements[0])
    {
      throw Error('Library element not found');
    }
    const libraryElement = libraryElements[0];
    if (libraryElement.progress.percentage !== 100)
    {
      await this.libService.downloadDocument(libraryElement);
    }
    await this.illService.placeItem(libraryElement.cachedFilePath);
    // await this.illService.deselectAll();
  }

  async sendContent(
    textContent: string,
    minSize: number,
    fontStyle: 'BOLD' | 'NORMAL',
    illustratorFontNormal: IllustratorFont,
    illustratorFontBold: IllustratorFont)
  {
    await this.currentDocumentService.linkContent(
      {
        content : textContent,
        description : '',
        minSize : minSize,
        type : 'Text'
      }, fontStyle, illustratorFontNormal, illustratorFontBold);
  }

  async askFontOptions (): Promise<FontResponse>
  {
    return new Promise<FontResponse>((resolve) =>
     {
      const dialogRef = this.dialog.open(PasteContentPopupComponent,
      {
        width: '90%',
      });
      dialogRef.afterClosed().subscribe(result =>
      {
        resolve(result);
      });
    });
  }
}
