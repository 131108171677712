import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IllustratorService } from 'src/app/services/illustrator.service';
import { IllustratorFont } from 'src/app/_models/smartmapping';
import * as _ from 'underscore';
import { UntypedFormControl } from '@angular/forms';
import { IllustratorConfigService } from 'src/app/services/illustrator-config.service';
import { FontSelection } from '../../../_models/Configuration';
import { FontResponse } from '../../../services/document-contents.service';
import { FeedbackService } from 'src/app/services/feedback.service';
@Component({
  selector: 'app-paste-content-popup',
  templateUrl: './paste-content-popup.component.html',
  styleUrls: ['./paste-content-popup.component.scss']
})
export class PasteContentPopupComponent {

  fonts: IllustratorFont [];
  normalFonts: IllustratorFont [];
  boldFonts: IllustratorFont [];
  normalFontSelected: IllustratorFont;
  boldFontSelected: IllustratorFont;
  filteredNormalFonts: IllustratorFont [];
  filteredBoldFonts: IllustratorFont [];

  fontSelection: FontSelection;

  normalFontSearchCtrl: UntypedFormControl = new UntypedFormControl();
  boldFontSearchCtrl: UntypedFormControl = new UntypedFormControl();
  constructor(
              private dialogRef: MatDialogRef<PasteContentPopupComponent>,
              private illustratorService: IllustratorService,
              private illConfigService: IllustratorConfigService,
              private feedbackService: FeedbackService
              )
  {
    this.loadData();
  }

  private async loadData()
  {
    await this.loadConfig();
    await this.loadFonts();
  }

  private async loadConfig()
  {
    try
    {
      const config = await this.illConfigService.getConfiguration();
      this.fontSelection = config.fontSelection;
    }
    catch (err)
    {
      this.feedbackService.notifyMessage(err);
    }
  }

  private async loadFonts()
  {
    try
    {
      this.fonts = await this.illustratorService.getIllustratorFonts();
      const boldRegex = new RegExp('bold|black|heavy', 'i');
      this.boldFonts = _.filter(this.fonts, f => boldRegex.test(f.style) || boldRegex.test(f.name));
      this.normalFonts = _.difference(this.fonts, this.boldFonts);
      this.normalFonts = _.sortBy(this.normalFonts, f => f.name);
      this.boldFonts = _.sortBy(this.boldFonts, f => f.name);
      this.normalFontSelected =
        _.find(this.normalFonts, nf => nf.name === this.fontSelection.normalFontName && nf.style === this.fontSelection.normalFontStyle);
      this.boldFontSelected =
        _.find(this.boldFonts, bf => bf.name === this.fontSelection.boldFontName && bf.style === this.fontSelection.boldFontStyle);
      this.normalFontSearchCtrl.setValue('');
      this.boldFontSearchCtrl.setValue('');
      this.applyFilterNormal('');
      this.applyFilterBold('');
    }
    catch (err)
    {
      this.feedbackService.notifyMessage(err);
    }
  }

  cancel()
  {
    this.dialogRef.close(undefined);
  }

  async confirm()
  {
    if (!this.normalFontSelected || !this.boldFontSelected)
    {
      this.feedbackService.notifyMessage('Need select two font styles to paste content');
    }
    else
    {
      await this.updateFontSelectionConfig();
      const result: FontResponse =
      {
        normalFont : this.normalFontSelected,
        boldFont:  this.boldFontSelected
      };
      this.dialogRef.close(result);
    }
  }

  applyFilterNormal(fontName: string)
  {
    if (!fontName || (fontName && fontName.length === 0))
    {
      this.filteredNormalFonts = this.normalFonts;
    }
    else
    {
      this.filteredNormalFonts = _.filter(this.normalFonts, nf => nf.name.toLowerCase().includes(fontName.toLowerCase()));
    }
  }

  applyFilterBold(fontName: string)
  {
    if (!fontName || (fontName && fontName.length === 0))
    {
      this.filteredBoldFonts = this.boldFonts;
    }
    else
    {
      this.filteredBoldFonts = _.filter(this.boldFonts, bf => (bf.name.toLowerCase().includes(fontName.toLowerCase())
                                                          || bf.family.toLowerCase().includes(fontName.toLowerCase())
                                                          || bf.style.toLowerCase().includes(fontName.toLowerCase())
    ));
    }
  }

  private async updateFontSelectionConfig()
  {
    try
    {
      const newFontSelection: FontSelection =
      {
        normalFontName: this.normalFontSelected.name,
        normalFontStyle: this.normalFontSelected.style,
        boldFontName: this.boldFontSelected.name,
        boldFontStyle: this.boldFontSelected.style
      };
      await this.illConfigService.setFontsOptions(newFontSelection);
    }
    catch (err)
    {
      this.feedbackService.notifyMessage(err);
    }
  }

}
