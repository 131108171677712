import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { LibraryElement, LibraryCacheService } from '../../../services/library-cache.service';
import {
  LibDocTypesNames,
  AuthService,
  CompanyRole,
  EventHubService,
  DocumentService
} from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { IllustratorService } from '../../../services/illustrator.service';
import * as _ from 'underscore';
import {  MatDialog } from '@angular/material/dialog';
import { TagEditDialogComponent } from '../../_shared/tag-edit-dialog/tag-edit-dialog.component';
import { KeyValue } from '@angular/common';
import { LibraryNamingDialogComponent } from '../library-naming-dialog/library-naming-dialog.component';
import { Subscription } from 'rxjs';
import { FeedbackService } from 'src/app/services/feedback.service';

@Component({
  selector: 'app-library-card',
  templateUrl: './library-card.component.html',
  styleUrls: ['./library-card.component.scss']
})
export class LibraryCardComponent implements OnDestroy {

  _libraryElement: LibraryElement;
  @Input() set libraryElement(libElement: LibraryElement)
  {
    libElement.fileExtension = this.getExtFileName(libElement.document.name);
    libElement.rootFileName = this.getRootFileName(libElement.document.name);
    this.libraryType = this.getLibraryType(libElement);
    this._libraryElement = libElement;
  }
  @Input() viewMode: 'list' | 'grid';

  @Output() libElementChanged: EventEmitter<void> = new EventEmitter<void>();

  libraryType: 'font' | 'image';

  busy: boolean;
  subscriptions: Subscription [] = [];

  constructor(private libraryCacheService: LibraryCacheService,
              private feedbackService: FeedbackService,
              private illService: IllustratorService,
              private dialog: MatDialog,
              private authService: AuthService,
              private eventHubService: EventHubService,
              private documentService: DocumentService)
  {
  }

  ngOnDestroy(): void
  {
    this.subscriptions.forEach(s => s.unsubscribe);
  }

  async download()
  {
    if (this.busy)
    {
      return;
    }
    try
    {
      this.busy = true;
      await this.libraryCacheService.downloadDocument(this._libraryElement);
      await this.libraryCacheService.copyToDownloadFolder(this._libraryElement);
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error downloading document', err);
    }
    finally
    {
      this.busy = false;
    }
  }

  async placeImage()
  {
    if (this.busy)
    {
      return;
    }
    if (this.libraryType !== 'image')
    {
      this.feedbackService.notifyMessage('Wrong library type');
      return;
    }
    try
    {
      this.busy = true;
      const openDocuments = await this.illService.getOpenDocuments();
      if (!openDocuments || openDocuments.length === 0)
      {
        this.feedbackService.notifyMessage('Please open a document first');
        return;
      }
      if (!this._libraryElement.cachedFilePath)
      {
        await this.libraryCacheService.downloadDocument(this._libraryElement);
      }
      await this.illService.placeItem(this._libraryElement.cachedFilePath);
    }
    catch (err)
    {
      const errMsg = 'Error adding library element';
      const errDescription = err.message ? err.message : 'Unexpected error';
      this.feedbackService.notifyError(`${errMsg}: ${errDescription}`, err);
    }
    finally
    {
      this.busy = false;
    }
  }

  async archive()
  {
    if (this.busy)
    {
      return;
    }
    try
    {
      this.busy = true;
      await this.libraryCacheService.archiveLibElement(this._libraryElement);
      this.feedbackService.notifyMessage('The resource was successfully archived');
      this.libElementChanged.emit();
    }
    catch (err)
    {
      this.feedbackService.notifyError('Unexpected error when trying to archive the resource', err);
    }
    finally
    {
      this.busy = false;
    }
  }

  async restore()
  {
    if (this.busy)
    {
      return;
    }
    try
    {
      this.busy = true;
      await this.libraryCacheService.restoreLibElement(this._libraryElement);
      this.feedbackService.notifyMessage('The resource was successfully restored');
      this.libElementChanged.emit();
    }
    catch (err)
    {
      this.feedbackService.notifyError('Unexpected error when trying to restore the resource', err);
    }
    finally
    {
      this.busy = false;
    }
  }

  openEditTags()
  {
    if (this._libraryElement.document.markedAsDeleted)
    {
      return;
    }
    this.dialog.open(TagEditDialogComponent, {
      data: {
        docVersion: this._libraryElement.version
      },
      disableClose: true
    });
  }

  private getLibraryType(libraryElement: LibraryElement): 'font' | 'image'
  {
    if (libraryElement?.document?.documentType?.name === LibDocTypesNames.imageTypeName)
    {
      return 'image';
    }
    if (libraryElement?.document?.documentType?.name === LibDocTypesNames.fontTypeName)
    {
      return 'font';
    }
    return null;
  }

  isAdminOrSuperUser()
  {
    const identity = this.authService.getCurrentIdentity();
    return (identity && identity.user?.isSuperUser || identity.role === CompanyRole.admin) ? true : false;
  }

  isSuperUser()
  {
    const identity = this.authService.getCurrentIdentity();
    return identity && identity.user?.isSuperUser;
  }

  async regeneratePreviews()
  {
    if (this.busy)
    {
      return;
    }
    try
    {
      this.busy = true;
      const versionId = this._libraryElement.version.id;
      const created = await this.documentService.recreatePreviews(versionId);
      if (created)
      {
        this.feedbackService.notifyMessage('Preview recreation successful. Please refresh the page');
      }
      else
      {
        this.feedbackService.notifyMessage('An issue occurred while recreating previews');
      }
    }
    catch (err)
    {
      this.feedbackService.notifyError(err.message, err);
    }
    finally
    {
      this.busy = false;
    }
  }

  getFormattedVersionSize(versionBytes: number): string
  {
    const kilobyte = 1024;
    const megabyte = kilobyte * kilobyte;
    const gigabyte = megabyte * kilobyte;

    if (versionBytes < kilobyte)
    {
      return versionBytes + ' B';
    }
    else if (versionBytes < megabyte)
    {
      return (versionBytes / kilobyte).toFixed(2) + ' KB';
    }
    else if (versionBytes < gigabyte)
    {
      return (versionBytes / megabyte).toFixed(2) + ' MB';
    }
    else
    {
      return (versionBytes / gigabyte).toFixed(2) + ' GB';
    }
  }

  searchLinkedParents(libraryElement: LibraryElement)
  {
    const versionNameIdPair: KeyValue<string, string> = {key: libraryElement.document.name, value: libraryElement.version.id};
    this.eventHubService.searchLinkedParentDocuments.emit(versionNameIdPair);
  }


  async edit(libraryElement: LibraryElement)
  {
    const name = await this.changeResourceName(libraryElement.rootFileName);
    if(!name)
    {
      return;
    }
    if(libraryElement.busy)
    {
      return;
    }
    libraryElement.busy = true;
    const originalName = libraryElement.document.name;
    try
    {
      libraryElement.document.name = `${name}${libraryElement.fileExtension}`;
      await this.documentService.updateDocumentName(libraryElement.document);
      this.feedbackService.notifyMessage('Name changed successfully');
    }
    catch(err)
    {
      libraryElement.document.name = originalName;
      libraryElement.rootFileName = this.getRootFileName(originalName);
      this.feedbackService.notifyError('Error changing name of library resource', err);
    }
    libraryElement.busy = false;
  }

  private getRootFileName(fileName: string)
  {
    const extRegex = /(?:\.([^.]+))?$/;
    fileName.replace(extRegex, '');
    return fileName.replace(extRegex, '');
  }

  private getExtFileName(fileName: string)
  {
    const extRegex = /(?:\.([^.]+))?$/;
    const ext = extRegex.exec(fileName)[0];
    return ext ? ext.toLowerCase() : undefined;
  }

  private changeResourceName(name: string) : Promise <string>
  {
    return new Promise<string>((resolve, reject) =>
      {
        const dialogRef = this.dialog.open( LibraryNamingDialogComponent, {
          data: {
            name: name
          },
          width: '75%',
          disableClose: true
        });
       this.subscriptions.push(dialogRef.afterClosed().subscribe(result => resolve(result)));
      });
  }
}
