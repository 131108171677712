import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Company } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';

@Component({
  selector: 'company-dialog',
  templateUrl: './company-dialog.component.html',
  styleUrls: ['./company-dialog.component.scss']
})
export class CompanyDialogComponent implements OnInit
{
  companyName: string;
  commModule: boolean;

  companyObj: Company;

  title: string;

  constructor(public dialogRef: MatDialogRef<CompanyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.companyObj = <Company>data.company;
    if (this.companyObj)
    {
      this.title = 'Edit company';
      this.companyName = this.companyObj.name;
      this.commModule = this.companyObj.subscriptionPlan?.hasTaskModule;
    }
    else
    {
      this.title = 'New company';
      this.companyObj = {};
    }
  }

  ngOnInit() {
  }

  ok(): void
  {
    this.companyObj.name = this.companyName;
    this.companyObj.subscriptionPlan.hasTaskModule = this.commModule;
    this.dialogRef.close(this.companyObj);
  }

  close(): void {
    this.dialogRef.close();
  }
}
