import { Component, Inject } from '@angular/core';
import {
   MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA 
} from '@angular/material/dialog';
import { LinkedFont } from '../../work/save-document/save-document.service';
import * as _ from 'underscore';
import { PlatformFont } from 'src/app/services/filesystem.service';
import { FontsPickerComponent } from '../fonts-picker/fonts-picker.component';
import {
  EventHubService,
  TranslationService
} from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';

@Component({
  selector: 'app-fonts-handler-dialog',
  templateUrl: './fonts-handler-dialog.component.html',
  styleUrls: ['./fonts-handler-dialog.component.scss']
})
export class FontsHandlerDialogComponent {

  linkedFonts: LinkedFont[] = [];

  constructor(
    public dialogRef: MatDialogRef<FontsHandlerDialogComponent>,
    private dialog: MatDialog,
    private eventHubService: EventHubService,
    private translationService: TranslationService,
    @Inject(MAT_DIALOG_DATA) public data: LinkedFont[])
  {
    this.linkedFonts = data;
  }

  async findFontFromPlatform(font: LinkedFont)
  {
    const platformFont = await this.openFontPicker();
    if (platformFont && platformFont.filepath)
    {
      font.path = platformFont.filepath;
    }
  }

  async inputFile(font: LinkedFont, files: FileList)
  {
    if (!files || files.length !== 1)
    {
      return;
    }
    const fontFile = <any>files[0]; // For some reason "File" type definition does not have the 'path' property.
    font.path = fontFile.path;
    font.filename = fontFile.name;
  }

  getFoundNotFoundString(fontName: string)
  {
    const result = this.translationService.getTranslation(
      'lid.ext.pages._shared.fonts-handler-dialog.fontNotFound',
      {
        fontName: fontName
      });
    return result;
  }

  cancel()
  {
    this.dialogRef.close();
  }

  ok()
  {
    const fixedFonts = _.filter(this.linkedFonts, font => !font.formatNotSupported && (font.documentVersion != null || font.path != null));
    this.dialogRef.close(fixedFonts);
  }

  goToManual()
  {
    this.eventHubService.openTutorial.emit();
  }

  allFontsReplaced(): boolean
  {
    return _.all(this.linkedFonts, f => f && !!f.path);
  }

  private openFontPicker(): Promise<PlatformFont>
  {
    return new Promise<PlatformFont>((resolve, reject) =>
    {
      const dialogRef = this.dialog.open(FontsPickerComponent, {
        disableClose: true,
        autoFocus: false
      });
      dialogRef.afterClosed().subscribe(result => resolve(result));
    });
  }

}
