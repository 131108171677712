import { Component, Input, ViewChild } from '@angular/core';
import {
  MoonDeskDocument,
  DocumentTag,
  DocumentFilter,
  FindAndReplacePair,
  MoonTask,
  ClassValue,
  ClassSelection,
  AuthService,
  DocumentService,
  ContentGroup,
  DocType,
  TranslationService,
  Class,
  RulesService,
  RuleConfiguration
} from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import * as _ from 'underscore';
import { CurrentDocumentService, CurrentDocumentChangedEvent } from './current-document.service';
import { DataIllustratorMap } from '../../../_models/smartmapping';
import { WorkManagerService, WorkManagerStatus, WorkManagerState } from '../work-manager/work-manager.service';
import { BulkService } from '../../../services/bulk.service';
import { SaveDocumentService, SaveDocumentStatus, SaveDocumentState } from '../save-document/save-document.service';
import { ClassSelectorComponent } from '../../class-selector/class-selector.component';
import { IllustratorService } from 'src/app/services/illustrator.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NutritionalTable } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/_models/nutritionalTable/NutritionalTable';
import { FeedbackService } from 'src/app/services/feedback.service';

@Component({
  selector: 'app-current-document',
  templateUrl: './current-document.component.html',
  styleUrls: ['./current-document.component.scss']
})
export class CurrentDocumentComponent
{
  tags: DocumentTag[];
  documentFilter: DocumentFilter;
  busy: boolean;
  loadingRules: boolean;
  currentDocument: MoonDeskDocument;
  currentTask: MoonTask;
  status: 'loading' | 'done' = 'done';

  findAndReplaceValues: FindAndReplacePair [] = [{}];
  caseSensitive: boolean = false;
  contentGroups: ContentGroup[] = [];
  rulesConfiguration: RuleConfiguration[] = [];
  nutritionTable: NutritionalTable;

  isContentTabSelected: boolean = false;

  /**
   * We show this button in Import mode AND IF
   * there's a known document with existing classification
   */
  showAutoAssignButton: boolean;
  showResetButton: boolean = true;
  hideHardResetButton: boolean = false;

  @Input() mode: 'Import' | 'Edit';

  @ViewChild('classSelectorComponent', {static: false}) classSelector: ClassSelectorComponent;

  selectedTabIndex: number = 0;

  constructor(private authService: AuthService,
              private currentDocumentService: CurrentDocumentService,
              private workManagerService: WorkManagerService,
              private bulkService: BulkService,
              private feedbackService: FeedbackService,
              private docService: DocumentService,
              private rulesService: RulesService,
              private saveService: SaveDocumentService,
              private illService: IllustratorService,
              private translationService: TranslationService)
  {
    this.currentDocumentService.documentChange.subscribe((e: CurrentDocumentChangedEvent) =>
      {
        const company = this.authService.getCurrentIdentity().company;
        if (!company)
        {
          return;
        }
        this.status = e.status;
        if (this.status === 'done')
        {
          if (this.currentDocument && this.currentDocument.companyId !== company.id && this.classSelector)
          {
            // if the current doc is from another company, we reset the filter
            this.classSelector.resetFilter(false);
          }

          const oldDocumentWorkingCopy = this.currentDocument?.workingCopy;
          this.currentDocument = this.mode === 'Edit' ? e.editDocument : e.importDocument;
          this.showAutoAssignButton = this.mode === 'Import' && e.editDocument !== undefined;

          if (this.mode === 'Import' && this.currentDocument && this.currentTask?.documentType)
          {
            this.showAutoAssignButton = false;
            this.showResetButton = false;
            this.hideHardResetButton = true;
            this.currentDocument.documentType = this.currentTask.documentType;
            this.currentDocument.documentTypeId = this.currentTask.documentTypeId;
            this.currentDocument.classValues = this.currentTask.taskClassValues.map(tcv => tcv.classValue);
          }

          const isSameDocument = this.currentDocument && oldDocumentWorkingCopy === this.currentDocument.workingCopy;
          if (!isSameDocument)
          {
            const taskWithClassification = this.currentTask?.documentType ? this.currentTask : undefined;
            this.documentFilter = this.getDocumentFilter(this.currentDocument, taskWithClassification);
            if (this.isContentTabSelected)
            {
                this.loadRuleConfigs();
            }
          }
        }
      });
    this.workManagerService.statusChange.subscribe((s: WorkManagerStatus) =>
      {
        this.busy = s.status !== WorkManagerState.done;
      });
    this.workManagerService.currentTaskChange.subscribe((task: MoonTask) =>
      {
        this.findAndReplaceValues = [{}];
        this.contentGroups = [];
        this.nutritionTable = undefined;
        if (task)
        {
          if (task.findAndReplace && task.findAndReplace.length > 0 && task.findAndReplace[0])
          {
            this.findAndReplaceValues = _.clone(task.findAndReplace);
          }
          if (task.documentContents && task.documentContents.length > 0)
          {
            this.contentGroups = _.clone(task.contentGroups);
          }
          if (task.nutritionalTable)
          {
            this.nutritionTable = task.nutritionalTable;
          }
        }
        this.currentTask = task;
        const taskHasClassification = this.currentTask?.documentType;
        if (taskHasClassification)
        {
          this.documentFilter = this.getDocumentFilter(this.currentDocument, this.currentTask);
        }
      });
    this.saveService.statusChange.subscribe((saveState: SaveDocumentStatus) =>
    {
      if (saveState.document != null && this.classSelector && this.mode === 'Import'
         && saveState.status === SaveDocumentState.done && saveState.mode === 'NewDoc')
      {
        this.classSelector.resetFilter(true);
      }
    });
  }

  onContentsClick(event: MatTabChangeEvent)
  {
    this.isContentTabSelected = event.tab.textLabel === 'Content';
    if (this.isContentTabSelected)
    {
      this.loadRuleConfigs();
    }
  }

  private async loadRuleConfigs()
  {
    if (this.loadingRules || !this.currentDocument || !this.currentDocument.documentType)
    {
      return;
    }
    try
    {
      this.loadingRules = true;
      this.rulesConfiguration = await this.rulesService.getRulesConfigs(this.currentDocument);
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error getting workspace rules', err);
    }
    finally
    {
      this.loadingRules = false;
    }
  }

  getDocumentFilter(doc: MoonDeskDocument, moonTask?: MoonTask): DocumentFilter
  {
    if (doc)
    {
      const documentTypeId = moonTask ? moonTask.documentTypeId : doc.documentTypeId;
      const classValues = moonTask ? moonTask.taskClassValues.map(tcv => tcv.classValue) : doc.classValues;
      const documentFilter = {
        companyId: doc.companyId,
        docTypeIds: documentTypeId ? [documentTypeId] : [],
        page: -1,
        skipPreviewUrls: true,
        classSelections: classValues ? this.mapClassSelections(classValues) : []
      };
      return documentFilter;
    }
    return undefined;
  }

  autoAssignClicked()
  {
    if (this.mode === 'Import' && this.currentDocumentService.editDocument !== undefined)
    {
      this.documentFilter = this.getDocumentFilter(this.currentDocumentService.editDocument);
      this.filtersChanged(this.documentFilter); // class selector component binding is not really two-way
    }
  }

  private mapClassSelections(classValues: ClassValue[]): ClassSelection[]
  {
    const classSelections: ClassSelection[] = [];
    for (const cv of classValues)
    {
      const existentClassSelection = _.find(classSelections, classSelection => classSelection.classId === cv.classId);
      if (existentClassSelection)
      {
        existentClassSelection.classValueIds.push(cv.id);
      }
      else
      {
        classSelections.push({classId: cv.classId, classValueIds: [cv.id], blank: false});
      }
    }
    console.log(classSelections);
    return classSelections;
  }

  updateContent()
  {
    this.currentDocumentService.updateFields();
  }

  async linkFieldMap(map: DataIllustratorMap)
  {
    this.currentDocumentService.linkFieldMap(map);
  }

  // CLASSIFIERS
  filtersChanged(filters: DocumentFilter)
  {
    try
    {
      const id = this.authService.getCurrentIdentity();
      if (!id || !id.user)
      {
        return;
      }
      if (filters && filters.docTypeIds && filters.docTypeIds.length === 1)
      {
        let doctype: DocType;
        if (id && id.company)
        {
          doctype = id.company.documentTypes.find(dt => dt.id === filters.docTypeIds[0]);
        }
        if (this.currentDocument && id && id.company && doctype)
        {
          const doctypechanged = !this.currentDocument.documentTypeId || !doctype ||
                                  this.currentDocument && doctype.id !== this.currentDocument.documentTypeId;
          this.currentDocument.documentType = doctype;
          this.currentDocument.documentTypeId = filters.docTypeIds[0];
          this.currentDocument.classValues = this.docService.mapClassSelector(filters.classSelections);
          if (doctypechanged)
          {
            this.currentDocumentService.updateFields();
          }
        }
      }
      else if (this.currentDocument)
      {
        this.currentDocument.documentType = undefined;
        this.currentDocument.documentTypeId = undefined;
        this.currentDocument.classValues = [];
      }
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error applying filter', err);
    }
  }

  bulkImportClicked = async () =>
  {
    await this.bulkService.openBulkImport();
  };

  async findReplace()
  {
    this.currentDocumentService.findReplace(this.findAndReplaceValues, this.caseSensitive);
  }

  async selectValues(pair: FindAndReplacePair, finding: boolean)
  {
    this.currentDocumentService.selectValues(pair, finding, this.caseSensitive);
  }

  showContent()
  {
    if (this.mode === 'Edit')
    {
      return this.currentDocument && this.currentDocument.id;
    }
    else if (this.mode === 'Import')
    {
      return this.currentDocument;
    }
  }

  showPDFDocumentHelp(): boolean
  {
    return this.mode === 'Import'
      && this.currentDocument && this.currentDocument.isPdf;
  }

  async savePDFasAi()
  {
    try
    {
      const pdfPath: string = this.currentDocument.workingCopy;
      if (!pdfPath.toLocaleLowerCase().endsWith('.pdf'))
      {
        console.error('File is not PDF');
        return;
      }
      const aiPath = pdfPath.replace('.pdf', '.ai');
      await this.illService.saveAsDocument(pdfPath, aiPath);
      this.currentDocument.isPdf = false;
    }
    catch (err)
    {
      const errMsg = this.translationService.getTranslation('lid.ext.pages.work.current-document.errorSavingAsAi');
      this.feedbackService.notifyError(errMsg, err);
    }
  }

  findReplaceDisabled()
  {
    return !this.findAndReplaceValues || this.findAndReplaceValues.length === 0 ||
           !this.findAndReplaceValues[this.findAndReplaceValues.length - 1].findValue;
  }

  async doContentClassification(selectedClasses: Class [])
  {
    const classifications = await this.currentDocumentService.doContentClassification(selectedClasses);
    const newDocFilter = {
      companyId: this.currentDocument.companyId,
      docTypeIds: [this.currentDocument.documentTypeId],
      page: -1,
      skipPreviewUrls: true,
      classSelections: this.mapClassSelections(classifications)
    };
    this.documentFilter = newDocFilter;
    this.filtersChanged(newDocFilter);
  }

  hasIAModule()
  {
    const id = this.authService.getCurrentIdentity();
    return id?.company?.subscriptionPlan?.hasAIModule;
  }

  get hasNutritionalTablesModule(): boolean
  {
    const id = this.authService.getCurrentIdentity();
    return id?.company?.configuration?.allowNutritionalTables;
  }

  get nutritionaTableTabLabel(): string
  {
    const valuesCount = this.nutritionTable.facts?.length ?? 0;
    return `Nutritional Table (${valuesCount})`;
  }

}
