import { Injectable } from '@angular/core';
// quagga is deprectaed, if we want to use this we should upgrade to quagga2
// import Quagga from 'quagga';

@Injectable({
  providedIn: 'root'
})
export class BarcodeService {

  constructor() { }

  async readBarcode(imageFilePath: string): Promise<string> {

    const xparam = (new Date).getTime();
    imageFilePath = `file://${imageFilePath}?${xparam}`;

    //the \ separator from windows are not valid in a file:// url...
    imageFilePath = imageFilePath.replace(/\\/g, '/');
    console.log(`readBarcode(${imageFilePath})`);

    return new Promise<string>((resolve, reject) =>
    {
      try
      {
        // quagga is deprectaed, if we want to use this we should upgrade to quagga2
        console.log('Barcode - calling Quagga');
        return null;
        /*Quagga.decodeSingle({
          src: imageFilePath,
          numOfWorkers: 0,  // Needs to be 0 when used within node
          decoder: {
              readers: ['ean_reader'] // List of active readers
          }},
          function(result) {
            // console.log("Barcode - callback function");
            // if (!result || typeof result.codeResult === 'undefined') {
            //   console.log("Barcode - callback function - reject ");
            //   reject('File Cannot be Decode, Please Try a Valid Barcode;');
            // }
            const code = result ? result.codeResult ? result.codeResult.code : undefined : undefined;
            console.log('Barcode - callback function - resolve ' + code);
            resolve(code);
          });*/
      }
      catch (err)
      {
        console.log(`Unexpected quagga error: ${err}`);
        reject(err);
      }
    });
  }
}
