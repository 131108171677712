import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import {
  ExportFilesIdentifiers,
  DocumentService,
  Progress,
  AuthService,
  AllowedDownloadFormat,
  CompanyRole
} from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { Subscription } from 'rxjs';
import { FilesystemService } from 'src/app/services/filesystem.service';
import { FeedbackService } from 'src/app/services/feedback.service';

interface ExportFileOption
{
  exportFile: string;
  allowed: boolean;
  description: string;
}

@Component({
  selector: 'app-download-dropdown',
  templateUrl: './download-dropdown.component.html',
  styleUrls: ['./download-dropdown.component.scss']
})
export class DownloadDropdownComponent implements OnDestroy, OnInit
{

  downloadAllCancelled: boolean;
  showDownloads: boolean;
  downloading: boolean;
  downloadingAll: boolean;
  downloadAllProgress: Progress;
  exportsOptions: ExportFileOption [] = [];
  allowedDownload: AllowedDownloadFormat;

  @Input() versionId: string;
  @Input() exportFiles: ExportFilesIdentifiers[];


  private subscriptions: Subscription [] = [];
  constructor(private documentService: DocumentService,
              private feedbackService: FeedbackService,
              private fileService: FilesystemService,
              private zone: NgZone,
              private authService: AuthService)
              {
              }

  ngOnInit()
  {
    const id = this.authService.getCurrentIdentity();
    const config = id.company?.configuration;
    if (id.reviewer)
    {
      this.allowedDownload = config?.externalReviewerAllowedDownloadFormat;
    }
    else
    {
      if (id.role >= CompanyRole.admin || id.user?.isSuperUser)
      {
        this.allowedDownload =
        {
          allowPng: true,
          allowJpg: true,
          allowAi: true,
          allowPdf: true,
          allowPdfSoft: true,
          allowAiOutline: true,
          allowZip: true
        };
      }
      else
      {
        this.allowedDownload = config?.internalUserAllowedDownloadFormat;
      }
    }
    this.exportsOptions = this.getExportOptions(this.exportFiles);
  }
  ngOnDestroy()
  {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  async download(exportFile?: string)
  {
    if (this.downloading)
    {
      return;
    }
    try
    {
      this.downloading = true;
      const url = await this.documentService.getFileUrlWithZipVerification(this.versionId, exportFile);

      const setProgress = (p: number) =>
      {
        this.zone.run(() =>
        {
          console.log(p);
        });
      };
      setProgress(1);
      const fileContent = await this.documentService.downloadFileWithUrl(url, p => setProgress(p.percentage * 0.9));
      setProgress(90);

      const fileName = this.getFilenameFromUrl(url);
      const downloadFolder = this.fileService.getDownloadsDirectory();
      const destinationPath = this.fileService.join(downloadFolder, fileName);

      await this.fileService.writeFileAsync(destinationPath, fileContent, {encoding: 'base64'});

      this.fileService.open(destinationPath);
      setProgress(100);
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error downloading file', err);
    }
    finally
    {
      this.downloading = false;
    }
  }

  private getFilenameFromUrl(url: string): string | null
  {
    const params = new URL(url).searchParams;
    const rscd = params.get('rscd');
    if (rscd)
    {
      const match = rscd.match(/filename=([^&]+)/);
      if (match)
      {
        return match[1];
      }
    }
    return this.versionId;
  }

  cancelDownloadAll()
  {
    this.downloadAllCancelled = true;
    this.downloadingAll = false;
    this.downloadAllProgress.cancelAction();
  }

  getExportOptions(exportFiles: ExportFilesIdentifiers[]): ExportFileOption[]
  {
    const result = [];
    exportFiles?.forEach(ef =>
    {
      let newValue: ExportFileOption = {exportFile: '', allowed: false, description: ''};
      if (ef !== 'fullImage.png')
      {
        switch (ef)
        {
          case 'document_outline.ai':
            newValue.allowed = this.allowedDownload?.allowAiOutline;
            newValue.description = 'Ai (Outline)';
            break;
          case 'document.ai':
            newValue.allowed = this.allowedDownload?.allowAi;
            newValue.description = 'Ai';
            break;
          case 'document_outline.pdf':
            newValue.allowed = this.allowedDownload?.allowPdf;
            newValue.description = 'PDF';
            break;
          case 'preview.pdf':
            newValue.allowed = this.allowedDownload?.allowPdfSoft;
            newValue.description = 'Light PDF';
            break;
          case 'document_export.jpg':
            newValue.allowed = this.allowedDownload?.allowJpg;
            newValue.description = 'JPG';
            break;
          case 'document_package.zip':
            newValue.allowed = this.allowedDownload?.allowZip;
            newValue.description = 'ZIP';
            break;
        };
      }
      newValue.exportFile = ef;
      result.push(newValue);
    });

    return result;
  }

}
