import { Injectable } from '@angular/core';
import { CSInterface } from 'csinterface-ts';
import { MoonDeskConfigService } from './moon-desk-config.service';
import { LoggingService } from '../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';

@Injectable({
  providedIn: 'root'
})
export class NodeClientService
{
  private csInterface: CSInterface;
  private initPromise: Promise<void>;

  private url: string;

  constructor(private moonDeskConfig: MoonDeskConfigService, private logger: LoggingService)
  {
    this.csInterface = new CSInterface();
    this.initPromise = this.init();
  }

  async init()
  {
    const config = await this.moonDeskConfig.getMoonDeskConfig();
    if (config.nodeServerExtensionname !== 'localdebug')
    {
      this.logger.trackTrace(`Opening node server extension  ${config.nodeServerExtensionname}`);
      this.csInterface.requestOpenExtension(config.nodeServerExtensionname, '');
    }
    this.url = `localhost:${config.nodeServerPort}`;
    this.logger.trackTrace(`Using node server ${this.url}`);
  }

  public async nodeServerTest(): Promise<string>
  {
    return '';
  }
}
