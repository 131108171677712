import { Injectable, EventEmitter } from '@angular/core';
import {
        MoonDeskDocument,
        LoggingService,
        AuthService,
        Severity,
        FieldType,
        FindAndReplacePair,
        DocumentService,
        DocumentChangedEvent,
        DocType,
        DocumentContent,
        Class,
        ClassValue
        } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import * as _ from 'underscore';
import { IllustratorService } from '../../../services/illustrator.service';
import { IllustratorItem, DataIllustratorMap, DocInfo, IllustratorFont } from '../../../_models/smartmapping';
import { WorkManagerService, WorkManagerState, WorkManagerStatus } from '../work-manager/work-manager.service';
import { SaveDocumentService } from '../save-document/save-document.service';
import Fuse from 'fuse.js';
import { FeedbackService } from 'src/app/services/feedback.service';

export interface CurrentDocumentStatus
{
  canSaveNewVersion: boolean;
  canSaveNewDocument: boolean;
}

export interface CurrentDocumentChangedEvent
{
  editDocument: MoonDeskDocument;
  importDocument: MoonDeskDocument;
  status: 'loading' | 'done';
}

@Injectable({
  providedIn: 'root'
})
export class CurrentDocumentService
{
  editDocument: MoonDeskDocument;
  importDocument: MoonDeskDocument;
  documentChange: EventEmitter<CurrentDocumentChangedEvent> = new EventEmitter<CurrentDocumentChangedEvent>();
  private activeDocHelper: DocInfo;

  private emit(status: 'loading' | 'done')
  {
    this.documentChange.emit({editDocument: this.editDocument, importDocument: this.importDocument, status: status});
  }

  constructor(private logger: LoggingService,
              private illService: IllustratorService,
              private feedbackService: FeedbackService,
              private authService: AuthService,
              private workManagerService: WorkManagerService,
              private saveDocService: SaveDocumentService,
              private docService: DocumentService)
  {
    this.workManagerService.activeDocumentChanged.subscribe( (i: DocInfo[]) => this.updateCurrentDocument(i));
    this.workManagerService.statusChange.subscribe((s: WorkManagerStatus) =>
    {
      if (s.status === WorkManagerState.done)
      {
        this.emit('done');
      }
      else
      {
        this.emit('loading');
      }
    });
    this.illService.SelectionChangedEvent.subscribe( (path: string) => this.documentChangedFromIllustrator(path));
    this.illService.DocumentSavedEvent.subscribe((path: string) => this.documentChangedFromIllustrator(path));
    this.docService.documentChanged.subscribe((e: DocumentChangedEvent) => this.documentChangedFromBackend(e));
    this.onInit();
  }

  async onInit()
  {
    console.log('@@@@@ CurrentDocService onInit, getOpenDocInfos');
    const activeDocInfos = await this.illService.getExtOpenDocInfos();
    console.log(activeDocInfos);
    this.updateCurrentDocument(activeDocInfos);
  }

  /**
   * Called on
   * - Startup
   * - When the activeDocumentChanges changed (from WorkMgrService)
   * Responsibilities
   * - Find the document according to its docInfos in the queue
   * - ELSE Get the known document object from Backend
   * - Create a new document object for import
   * IMPORTANT
   * With the user switching quickly between documents, it's very likely that this
   * function is called several times before one call can finish (thinking of the
   * backend call...)!
   * That's why we store the DocInfo in this.activeDocHelper, and when the function
   * finishes (finally block), we can check if we're still in the last call. If not,
   * the function results are ignored, to allow the latest call to finish!
   */

  async updateCurrentDocument(activeDocInfos: DocInfo[])
  {
    const id = this.authService.getCurrentIdentity();
    if (!id || !id.company || !id.user)
    {
      return;
    }

    const s = this.workManagerService.status;

    if (!activeDocInfos || (activeDocInfos.length > 0 && !_.any(activeDocInfos, i => i.active)))
    {
      this.logger.trackTrace('@@@@@@@ Error this cant be - no infos OR a list with docInfos and none is active', Severity.Critical);
      console.log(activeDocInfos);
      return;
    }

    let knownDocument: MoonDeskDocument;
    let active: DocInfo;
    try
    {
      active = _.find(activeDocInfos, i => i.active);

      if (active !== undefined && s.status !== WorkManagerState.done)
      {
        if (this.importDocument && (this.importDocument.workingCopy === active.document || s.status === WorkManagerState.saving))
        {
          console.log('Ignoring updateCurrentDocument for the same file while workmgrservice is busy');
          return;
        }
      }

      this.activeDocHelper = active;
      if (active && active.document)
      {
        this.emit('loading');
        // IF there's a docId in the active document
        //    => if we find it in the queue OK
        //    => else we ask the backend
        // IF there's still no document found
        //    => let's create a new object (for import)
        if (active)
        {
          const qe = _.find(this.workManagerService.getQueue(), q => q.document && q.document.workingCopy === active.document);
          if (qe)
          {
              if (qe.document.id !== active.docId)
              {
                this.logger.trackTrace('Different documentId, probably because of a cloned company');
                console.log(`Queue info ${qe.document.id}: ${qe.document.workingCopy}`);
                console.log(`Actual inf ${active.docId}: ${active.document}`);
                await this.illService.setExtDocInfo(qe.document.workingCopy, qe.document.id, qe.document.editingVersion.versionNumber,
                                                    qe.document.editingVersion.minorVersionNumber);
                if (qe.document.editingVersion?.restoreInformation?.linkReplacements)
                {
                  await this.saveDocService.fixLinkedFiles(qe.document.workingCopy,
                                                           qe.document.editingVersion?.restoreInformation?.linkReplacements);
                }
                const compatibilityEnabled = id.company.configuration.saveAiWithCompatibility;
                await this.illService.saveDocument(qe.document.workingCopy, compatibilityEnabled);
              }
            knownDocument = this.cloneDocument(qe.document);
          }
          else if (active.docId)
          {
            const x = await this.docService.getDocument(active.docId);
            if (x && x.companyId === id.company.id)
            {
              x.editingVersion = x.latestVersion;
              x.workingCopy = active.document;
              knownDocument = x;
            }
          }
        }
      }
    }
    catch (err)
    {
      let errorMsg = 'Error updating current document';
      if (err.message)
      {
        errorMsg = `${errorMsg}: ${err.message}`;
      }
      this.feedbackService.notifyError(errorMsg, err);
    }
    finally
    {
      if (active !== this.activeDocHelper)
      {
        this.logger.trackTrace(
          `CurrentDocService = updateCurrentDocument finished, but was overlapped asynchronously by ` +
          `${this.activeDocHelper ? this.activeDocHelper.document : 'none'}`, Severity.Information);
      }
      else
      {
        console.log(`@@@@@@@ CurrentDocService has a new document`);
        this.editDocument = knownDocument;
        const noneLibraryTypes = _.filter(id.company.documentTypes, dt => !dt.isLibraryType);
        if (knownDocument)
        {
          const newDoc = this.cloneDocument(knownDocument);
          newDoc.editingVersion.documentTags = [];

          if (noneLibraryTypes.length === 1)
          {
            newDoc.documentType = noneLibraryTypes[0];
            newDoc.documentTypeId = newDoc.documentType.id;
            newDoc.classValues = [];
          }
          else
          {
            if (this.importDocument && this.importDocument.workingCopy === newDoc.workingCopy)
            {
              newDoc.documentTypeId = this.importDocument.documentTypeId;
              newDoc.documentType = this.importDocument.documentType;
              newDoc.classValues = this.importDocument.classValues;
            }
            else
            {
              newDoc.documentType = undefined;
              newDoc.documentTypeId = undefined;
              newDoc.classValues = [];
            }
          }
          this.importDocument = newDoc;
        }
        else if (active && active.document)
        {
          const newDoc = this.saveDocService.createDocumentObject(active.document);
          this.illService.addDocPathMapping(newDoc.workingCopy, active.document);

          if (noneLibraryTypes.length === 1)
          {
            newDoc.documentType = noneLibraryTypes[0];
            newDoc.documentTypeId = newDoc.documentType.id;
            newDoc.classValues = [];
          }
          else
          {
            if (this.importDocument && this.importDocument.workingCopy === newDoc.workingCopy)
            {
              newDoc.documentTypeId = this.importDocument.documentTypeId;
              newDoc.documentType = this.importDocument.documentType;
              newDoc.classValues = this.importDocument.classValues;
            }
            else
            {
              newDoc.documentType = undefined;
              newDoc.documentTypeId = undefined;
              newDoc.classValues = [];
            }
          }
          this.importDocument = newDoc;
        }
        else
        {
          this.importDocument = undefined;
        }
        await this.updateFields();
        this.emit('done');
      }
    }
  }

  private async documentChangedFromBackend(event: DocumentChangedEvent)
  {
    try
    {
      if (event.documents && this.editDocument && event.action !== 'Delete')
      {
        const currentX = _.find(event.documents, d => d.id === this.editDocument.id);
        if (currentX)
        {
          currentX.workingCopy = this.editDocument.workingCopy;
          currentX.editingVersion = currentX.latestVersion;
          currentX.documentType = _.find(this.authService.getCurrentIdentity().company.documentTypes,
                                         dt => dt.id === currentX.documentType.id);
          this.editDocument = currentX;
          await this.updateFields();
          this.emit('done');
        }
      }
      else if (event.action === 'VersionState')
      {
        if (this.editDocument && this.editDocument.editingVersion.id === event.version.id)
        {
          this.editDocument.editingVersion.status = event.version.status;
          this.emit('done');
        }
      }
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error updating current document', err);
      this.emit('done');
    }
  }

  async documentChangedFromIllustrator(path: string)
  {
    if (!this.importDocument || path !== this.importDocument.workingCopy)
    {
      return;
    }
    if (this.workManagerService.status.status !== WorkManagerState.saving
       && this.workManagerService.status.status !== WorkManagerState.loadingelement)
    {
      await this.updateFields();
      this.emit('done');
    }
  }

  async updateFields()
  {
    if (this.importDocument)
    {
      // the importDocument is always there, in case of an existing AND of an unknown document
      // anyway the editDocument references to the same workingCopy, so we only get the items once!
      const illItems: IllustratorItem[] = await this.illService.getItems(this.importDocument.workingCopy);
      if (this.importDocument.documentType)
      {
        const dataMaps = this.createDataIllustratorMap(illItems, this.importDocument.documentType, this.importDocument.workingCopy);
        this.importDocument.dataIllustratorMaps = dataMaps;
      }
      else
      {
        this.importDocument.dataIllustratorMaps = [];
      }
      if (this.editDocument)
      {
        if (this.editDocument.documentType)
        {
          const dataMaps = this.createDataIllustratorMap(illItems, this.editDocument.documentType, this.editDocument.workingCopy);
          this.editDocument.dataIllustratorMaps = dataMaps;
        }
        else
        {
          this.editDocument.dataIllustratorMaps = [];
        }
      }
    }
  }

  cloneDocument(doc: MoonDeskDocument): MoonDeskDocument
  {
    // let result = JSON.parse(JSON.stringify(doc)); //don't! circular json problems
    const result = _.clone(doc);
    result.classValues = _.clone(doc.classValues);
    result.latestVersion = _.clone(result.latestVersion);
    if (result.latestVersion)
    {
      result.latestVersion.documentId = doc.id;
      result.latestVersion.documentTags = _.clone(doc.latestVersion.documentTags);
    }
    if (!result.editingVersion)
    {
      result.editingVersion = result.latestVersion;
    }
    else
    {
      result.editingVersion = _.clone(result.editingVersion);
      result.editingVersion.documentTags = _.clone(result.editingVersion.documentTags);
    }
    result.editingVersion.documentId = doc.id;
    return result;
  }

  /**
   * For all the fieldTypes (fields) that are required for a document (depending on its configuration),
   * this functions looks up the equivalent IllustratorItem in the document. Logically, the mapping
   * happens through the code. Each map has a status in the end:
   * - 'notassigned' => there was no IllustratorItem that matches the code
   * - 'ambiguous' => there are several IllustratorItems that match the code
   * - 'wrongtype' => the IllustratorItem is of the wrong type
   * - 'ok' => there is exactly one IllustratorItem with the correct code and type
   */
  createDataIllustratorMap(illItems: IllustratorItem[], documentType: DocType, workingCopy: string): DataIllustratorMap[]
  {
    const id = this.authService.getCurrentIdentity();
    const fieldTypes = _.find(id.company.documentTypes, docType => docType.id === documentType.id).fieldTypes;
    const filteredItems: IllustratorItem[] = [];
    illItems.forEach(ii =>
    {
      if (ii && (ii.code || ii.content))
      {
        filteredItems.push(ii);
      }
    });
    const barcodeValue: string = '';
    const result: DataIllustratorMap[] = [];
    for (let i = 0 ; i < fieldTypes.length ; i++)
    {
      const ft = fieldTypes[i];
      if (ft.type === 'Barcode')
      {
        const barcodeMap: DataIllustratorMap =
        {
          illustratorItems: [{
            code: ft.code,
            content: barcodeValue,
            type: 'Barcode'
          }],
          fieldType: ft,
          status: barcodeValue ? 'ok' : 'notassigned',
          isSelected: false,
        };
        result.push(barcodeMap);
      }
      else // if(ft.type == 'Text')
      {
        // the illustrator items that match the code of the datatype
        const matchingItems = _.filter(filteredItems, ii => ii.code === ft.code);
        const map: DataIllustratorMap =
        {
          illustratorItems: matchingItems,
          fieldType: ft,
          status: this.getStatus(ft, matchingItems),
          isSelected: _.any(matchingItems, m => m.selected === true)
        };
        result.push(map);
      }
    }

    return result;
  }

  private getStatus(fieldType: FieldType, items: IllustratorItem[]): 'notassigned' | 'ambiguous' | 'wrongtype' | 'wrongvalue' | 'ok'
  {
    if (fieldType.type === 'Barcode')
    {
      return 'ok';
    }

    if (!items || items.length === 0)
    {
      return 'notassigned';
    }

    if (items.length > 1)
    {
      return 'ambiguous';
    }

    if (!this.correctType(fieldType.type , items[0].type))
    {
      return 'wrongtype';
    }

    return 'ok';
  }

  private correctType(fieldType: 'Text' | 'Barcode', illustratorType: string): boolean
  {
    if (fieldType === 'Text')
    {
      return illustratorType === 'TextFrame';
    }

    return fieldType === 'Barcode'; // for a barcode we accept any illustrator field for now
  }

  async linkFieldMap(map: DataIllustratorMap)
  {
    let result;
    if (!map || !map.fieldType)
    {
      result = 'No fieldType provided for mapping';
    }
    try
    {
      this.logger.trackTrace(`Recode selection '${map.fieldType.code}'`);
      result = await this.illService.recodeSelection(this.importDocument.workingCopy, map.fieldType.code,
                                                     this.translateFieldtypeName(map.fieldType.type));
      if (result && !result.startsWith('Error 1200'))
      {
        if (result.startsWith('Error 9551'))
        {
          result = 'Can\'t link field while you\'re editing the text';
        }
        else
        {
          result = 'Invalid selection for linking';
        }
      }
    }
    catch (err)
    {
      result = err;
    }
    if (result)
    {
      this.feedbackService.notifyError('Error mapping field', result);
    }
    else
    {
      await this.updateFields();
      this.emit('done');
    }
  }

  async linkContent(content: DocumentContent , type: 'BOLD' | 'NORMAL', illustratorFontNormal: IllustratorFont,
                    illustratorFontBold: IllustratorFont)
  {
    try
    {
      const warning = await this.illService.linkContentToCurrentDocument(content, type, illustratorFontNormal, illustratorFontBold);
      if (warning)
      {
        this.feedbackService.notifyMessage(warning);
      }
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error mapping content', err);
    }
  }

  private translateFieldtypeName(fieldType: 'Text' | 'Barcode'): 'TextFrame' | undefined
  {
    if (fieldType === 'Text')
    {
      return 'TextFrame';
    }
    return undefined;
  }

  async findReplace(findAndReplaceValues: FindAndReplacePair[], caseSensitive: boolean)
  {
    try
    {
      let totalReplaced: number = 0;
      this.illService.deselectDocumentElements(this.importDocument.workingCopy);
      for (let i = 0; i < findAndReplaceValues.length; i++)
      {
        const pair = findAndReplaceValues[i];
        if (pair.findValue)
        {
          pair.result = await this.illService.findAndReplace(this.importDocument.workingCopy, pair.findValue,
                                                                pair.replaceValue, pair.wholeWord, caseSensitive);
          totalReplaced += pair.result;
        }
        else
        {
          this.feedbackService.notifyMessage('Empty find value not allowed');
        }
      }
      if (totalReplaced === 0)
      {
        await this.feedbackService.notifyMessage('Text not found');
      }
      else
      {
        await this.feedbackService.notifyMessage('Replacement completed');
      }
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error replacing values', err);
    }
  }

  // private allPairFormats(pair: FindAndReplacePair): FindAndReplacePair[]
  // {
  //   const result: FindAndReplacePair[] = [];
  //   const regeOnlySpaces = /^\s+$/g;
  //   if (!regeOnlySpaces.test(pair.findValue))
  //   {
  //     const onlyNumbers = new RegExp('^[0-9]', 'g');
  //     if (!onlyNumbers.test(pair.findValue))
  //     {
  //        result.push({findValue: (pair.findValue ? pair.findValue.toLocaleLowerCase() : ''),
  //                     replaceValue: (pair.replaceValue ? pair.replaceValue.toLocaleLowerCase() : ''), wholeWord: pair.wholeWord} );
  //        result.push({findValue: (pair.findValue ? pair.findValue.toLocaleUpperCase() : ''),
  //                     replaceValue: (pair.replaceValue ? pair.replaceValue.toLocaleUpperCase() : ''), wholeWord: pair.wholeWord});
  //     }
  //     if (!onlyNumbers.test(pair.findValue.substr( 0, 1 )))
  //     {
  //       result.push({findValue: (pair.findValue ? pair.findValue.substr( 0, 1 ).toUpperCase() +
  //                     pair.findValue.toLocaleLowerCase().substr( 1 ) : ''),
  //                     replaceValue: (pair.replaceValue ? pair.replaceValue.substr( 0, 1 ).toUpperCase() +
  //                     pair.replaceValue.toLocaleLowerCase().substr( 1 ) : ''),
  //                     wholeWord: pair.wholeWord});
  //     }
  //   }
  //   const exist = result.find(function(element)
  //   {
  //     return element.findValue === pair.findValue;
  //   });
  //   if (!exist)
  //   {
  //     result.push({findValue: (pair.findValue ? pair.findValue : ''), replaceValue: (pair.replaceValue ? pair.replaceValue : ''),
  //                   wholeWord: pair.wholeWord});
  //   }
  //   return result;
  // }

  async selectValues(pair: FindAndReplacePair, finding: boolean, caseSensitive: boolean)
  {
    if ((pair.findValue && finding === true) || (pair.replaceValue && finding === false))
    {
      this.illService.deselectDocumentElements(this.importDocument.workingCopy);
      const searchText = finding ? pair.findValue : pair.replaceValue;
      await this.illService.find(this.importDocument.workingCopy, searchText, pair.wholeWord, false, caseSensitive);
    }
  }

  async removeSelectedText()
  {
    try
    {
      await this.illService.removeCurrentDocumentSelectedText();
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error removing selected text', err);
    }
  }

  // Offer classification based on coincidences for the document.
  async doContentClassification(selectedClasses: Class []): Promise <ClassValue []>
  {
    try
    {
      const documentText = await this.illService.getAllTextPlain(this.importDocument.workingCopy);
      const textArray = [{ id: 1, content: documentText }];
      // const Fuse = require('fuse.js');
      const plainClassList = this.docService.getPlainClassList(selectedClasses);
      const classValuesToFind: ClassValue [] = [];
      for(const classList of plainClassList)
      {
        for(const classValue of classList.classValues)
        {
          classValuesToFind.push(
          {
            name: classValue.name,
            id: classValue.id,
            classId: classValue.classId,
            parentId: classList.id,
            parentName: classList.name,
            class : null,
            code : null
          });
        }
      }
      const options =
      {
        threshold: 0.2, // Ajuste de sensibilidad
        distance: 500000,
        shouldSort: true,
        keys: ['content'],
        includeScore: true
      };
      const results: ClassValue [] = [];
      const fuse = new Fuse(textArray, options);
      for(const classValue of classValuesToFind)
      {
        const r = fuse.search(classValue.name);
        if (r.length > 0)
        {
          // r.classValue = classValue;
          results.push(classValue);
        }
      }
      return results;
    }
    catch(err)
    {
      console.log('Error searching coincidence with classValues : ', err);
    }
  }
}
