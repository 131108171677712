import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import {
        AuthService,
        Identity,
        MoonTask,
        NotificationHubService,
        PushMessage,
        SubTaskMessage,
        TasksService
        } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import * as _ from 'underscore';
import { TaskMessage } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/_models/tasks/TaskMessage';
import { DatePipe } from '@angular/common';
import { FeedbackService } from 'src/app/services/feedback.service';


interface TaskAndSubTaskMsg
{
  taskMessage?: TaskMessage;
  subTaskMessage?: SubTaskMessage;
  msgTimestamp?: Date;
}


@Component({
  selector: 'app-task-chat',
  templateUrl: './task-chat.component.html',
  styleUrls: ['./task-chat.component.scss'],
  providers: [DatePipe]
})
export class TaskChatComponent implements AfterViewInit, OnDestroy {


  _task: MoonTask;
  allMessages: TaskAndSubTaskMsg[] = [];
  @Input() set task(task: MoonTask)
  {
    this._task = JSON.parse(JSON.stringify(task));
    if (!this._task.taskMessages)
    {
      this.loadTaskMessages(1, true);
    }
  }

  message: string;
  busy: boolean;

  currentPage: number = 0;
  totalPages: number = 0;

  subs: Subscription [] = [];
  private identity: Identity;
  @ViewChild('taskMessages') private taskChatContainer: ElementRef;

  constructor( private tasksService: TasksService ,
               private notificationHubService: NotificationHubService,
               private authService: AuthService,
               private feedbackService: FeedbackService,
               private datePipe: DatePipe)
  {
      this.identity = this.authService.getCurrentIdentity();
      if (!this._task)
      {
        // error
      }
      this.subs.push(this.notificationHubService.taskMessagePushed.subscribe((notif: PushMessage) =>
      {
        console.log('NEW MESSAGE RECEIVED');
        console.log(notif);
        const taskMsg = notif.taskNotification?.taskMessage;
        const subTaskMsg = notif.taskNotification?.subTaskMessage;
        if (taskMsg && taskMsg.taskId && this._task && taskMsg.taskId === this._task.id)
        {
          // to not add duplicates in the chat, better we check...
          const taskMessages = _.filter(this.allMessages, msgs => msgs.taskMessage != null);
          if (!_.any(taskMessages, msg => msg.taskMessage?.id === taskMsg.id))
          {
            this.allMessages.push({taskMessage: taskMsg, msgTimestamp: taskMsg.timestampUtc});
          }
        }
        else if (subTaskMsg && subTaskMsg.subTaskId && this._task.subTasks &&
          this._task.subTasks.length > 0 && _.any(this._task.subTasks, st => st.id === subTaskMsg.subTaskId))
        {
          const stMessages = _.filter(this.allMessages, msgs => msgs.subTaskMessage != null);
          // to not add duplicates in the chat, better we check...
          if (!_.any(stMessages, stm => stm.subTaskMessage?.id === subTaskMsg.id))
          {
            this.allMessages.push({subTaskMessage: subTaskMsg, msgTimestamp: subTaskMsg.timestampUtc});
          }
        }
      }));

  }

  ngAfterViewInit(): void
  {
    this.scrollToBottom();
  }

  ngOnDestroy()
  {
    this.subs.forEach( s => s.unsubscribe());
  }

  scrollToBottom(): void
  {
    console.log('TOOOOOOOO THE BOTTOOOM');
    this.taskChatContainer.nativeElement.scrollTop = this.taskChatContainer.nativeElement.scrollHeight;
  }

  async sendMessage()
  {
    if (this.busy || this.isEmptyOrSpaces(this.message))
    {
      return;
    }
    try
    {
      this.busy = true;
      const taskMessage: TaskMessage =
      {
        taskId: this._task.id,
        text: this.message,
        userId: this.identity.user?.id,
        reviewerId: this.identity.reviewer?.id
      };
      const result = await this.tasksService.sendTaskMessage(taskMessage);
      this._task.taskMessages?.push(result);
      this.message = '';
      this.feedbackService.notifyMessage('Message sent');
      this.allMessages = this.getAllMessages(this._task);
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error sending task message', err);
    }
    finally
    {
      this.busy = false;
    }
    await this.delay(500);
    this.scrollToBottom();
  }

  private async loadTaskMessages(page: number, scrollToBottom?: boolean)
  {
    if (this.busy || (this.currentPage !== 0 && this.currentPage >= this.totalPages))
    {
      return;
    }
    try
    {
      this.busy = true;
      const response = await this.tasksService.getTaskMessages({taskId: this._task.id, page: page});
      if (!this._task.taskMessages)
      {
        this._task.taskMessages = [];
      }
      this._task.taskMessages = _.union(this._task.taskMessages, response.messages);
      this.currentPage = page;
      this.totalPages = response.pageCount;
      this.allMessages = this.getAllMessages(this._task);
      if (scrollToBottom)
      {
        await this.delay(200);
        this.scrollToBottom();
      }
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error getting task messages', err);
    }
    finally
    {
      this.busy = false;
    }
  }

  async onScroll(scrollEvent: any)
  {
    const isOnTop = scrollEvent.target.scrollTop < 10;
    const areMorePages = this.currentPage < this.totalPages;
    if (!this.busy && isOnTop && areMorePages)
    {
      const oldScrollHeight = this.taskChatContainer.nativeElement.scrollHeight;

      await this.loadTaskMessages(this.currentPage + 1, false);
      await this.delay(100);

      const scrollHeight = this.taskChatContainer.nativeElement.scrollHeight - oldScrollHeight;
      // To allow for further scrolling in case of additional pages:
      scrollEvent.target.scrollTop = scrollHeight;
    }
  }

  private delay(ms: number)
  {
    return new Promise( resolve => setTimeout(resolve, ms));
  }

  isEmptyOrSpaces(str: string)
  {
    return str == null || str.match(/^ *$/) !== null;
  }

  isOwnMessage(msg: TaskAndSubTaskMsg)
  {
    let isOwn = false;
      if (msg.taskMessage)
      {
        if (msg.taskMessage.user)
        {
          isOwn = msg.taskMessage.user.id === this.identity.user?.id;
        }
        else if (msg.taskMessage.reviewer)
        {
          isOwn = msg.taskMessage.reviewer.id === this.identity.reviewer?.id;
        }
      }

      else if (msg.subTaskMessage)
      {
        if (msg.subTaskMessage.user)
        {
          isOwn = msg.subTaskMessage.user.id === this.identity.user?.id;
        }
        else if ( msg.subTaskMessage.reviewer)
        {
          isOwn = msg.subTaskMessage.reviewer.id === this.identity.reviewer?.id;
        }
      }
    return isOwn;
  }


  getMessageOwnerName(msg: TaskAndSubTaskMsg)
  {
    let name = '';
    if (msg.taskMessage)
    {
        if (msg.taskMessage.user)
        {
          name = `${msg.taskMessage.user.firstName} ${msg.taskMessage.user.lastName}`;
        }
        else if (msg.taskMessage.reviewer)
        {
          name = `${msg.taskMessage.reviewer.name ? msg.taskMessage.reviewer.name : msg.taskMessage.reviewer.email}`;
        }
    }
    else if (msg.subTaskMessage)
    {
        if (msg.subTaskMessage.user)
        {
          name = `${msg.subTaskMessage.user.firstName} ${msg.subTaskMessage.user.lastName}`;
        }
        else if (msg.subTaskMessage.reviewer)
        {
          name = `${msg.subTaskMessage.reviewer.name ? msg.subTaskMessage.reviewer.name : msg.subTaskMessage.reviewer.email}`;
        }
    }
    return name;
  }


  private getAllMessages(task: MoonTask): TaskAndSubTaskMsg[]
  {
    if (!task)
    {
      console.log('error');
      return [];
    }
    const allMessages: TaskAndSubTaskMsg[] = [];
    task.taskMessages?.forEach(msg =>
    {
      allMessages.push(
      {
        taskMessage: msg,
        msgTimestamp: msg.timestampUtc
      });
    });
    task.subTasks.forEach(st =>
    {
      if (st.subTaskMessages)
      {
        st.subTaskMessages.forEach(msg =>
        {
          allMessages.push(
          {
            subTaskMessage: msg,
            msgTimestamp: msg.timestampUtc
          });
        });
      }
    });
    const result = _.sortBy(allMessages, msg => msg.msgTimestamp);
    return result;
  }

  compareDates(element: TaskAndSubTaskMsg): boolean
  {
    const previousElementIndex: number = this.allMessages.indexOf(element) - 1;
    const date1 = new Date(element.msgTimestamp).toLocaleDateString();
    const date2 = new Date(this.allMessages[previousElementIndex].msgTimestamp).toLocaleDateString();
    return date1 === date2;
  }

  getDateString(message: TaskAndSubTaskMsg): string
  {
    const currentDate = new Date();
    const daysDifference = this.getDatesDifferenceDays(currentDate, message.msgTimestamp);
    if (daysDifference === 0)
    {
      return 'Today';
    }
    else if (daysDifference === 1)
    {
      return 'Yesterday';
    }
    else
    {
      return this.datePipe.transform(message.msgTimestamp , 'MMMM d, y');
    }
  }

  private getDatesDifferenceDays(date1: Date, date2: Date): number
  {
    const formattedDate1 = new Date(date1).setHours(0, 0, 0, 0);
    const formattedDate2 = new Date(date2).setHours(0, 0, 0, 0);
    const daysDifference = Math.floor(Math.abs(<any>formattedDate1 - <any>formattedDate2) / (1000 * 60 * 60 * 24));
    return daysDifference;
  }


  getDocumentName(msg: TaskAndSubTaskMsg)
  {
    let documentName = '';
    if (!msg.subTaskMessage.subTask && msg.subTaskMessage.subTaskId)
    {
      msg.subTaskMessage.subTask = _.find(this._task.subTasks, st => st.id === msg.subTaskMessage.subTaskId);
    }
    if (msg.subTaskMessage?.subTask?.document?.classValueString)
    {
      documentName = msg.subTaskMessage.subTask.document?.classValueString;
    }
    else if (msg.subTaskMessage?.subTask?.subTaskInfos?.classValueString)
    {
      documentName = msg.subTaskMessage.subTask.subTaskInfos.classValueString;
    }
    else
    {
      documentName = msg.subTaskMessage.subTask.document.moonNumber;
    }
    return documentName;
  }

  isDeletedMessage(tstMessage: TaskAndSubTaskMsg)
  {
    const deletedTimestamp = tstMessage.taskMessage ? tstMessage.taskMessage.deletedTimestampUtc :
                                                      tstMessage.subTaskMessage.deletedTimestampUtc;
    return deletedTimestamp > tstMessage.msgTimestamp;
  }


  setCurrentComment()
  {
    this.feedbackService.notifyMessage('Please , Go to web review to open this comment');
  }
}
